<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="輸入密碼"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="account">
            <span style="font-weight: bold">帳號</span>
            <span>{{ phoneText }}</span>
          </div>
          <input type="password" placeholder="請輸入密碼" v-model="password">
          <p class="errorMsg" v-if="passwordEmpty">密碼不可為空</p>
          <mainButton action-name="登入" @action="login"></mainButton>
          <div class="forgetPassword" @click="forgetPassword">
            忘記密碼？
          </div>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import loading from "../../components/loading";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    loading
  },
  data(){
    return{
      phone: '',
      phoneText: '',
      password: '',
      passwordEmpty: false,
      isLoading: false,
    }
  },
  mounted() {
    let phone = window.localStorage.getItem('phone')
    this.phone = phone
    let phoneArray = phone.split('')
    this.phoneText = phoneArray[0] + phoneArray[1]+ phoneArray[2]+ phoneArray[3]+ '-' +phoneArray[4]+ phoneArray[5]+ phoneArray[6] + '-'+ phoneArray[7]+ phoneArray[8]+ phoneArray[9]
  },
  methods:{
    login(){
      this.checkPassword()
      if(!this.passwordEmpty){
        this.isLoading = true
        let url = process.env.VUE_APP_APIUrl + 'bindLineOA'
        let data = {
          "mobile": this.phone,
          "password": this.password
        }
        this.axios.post(url, data, {
          headers:{
            Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        }).then( res =>{
          console.log(res)
          this.$swal({
            text: res.data.data,
          })
          // alert(res.data.data)
          this.isLoading = false
          this.$router.push({ path : '/member/memberData'})
        })
        .catch(e =>{
          console.log(e)
          this.$swal({
            text: e.response.data.msg,
          })
          // alert(e.response.data.msg)
          this.isLoading = false
          // liff.closeWindow()
        })
      }
    },
    forgetPassword(){
      this.$router.push({path: '/member/forgetPassword'})
    },
    checkPassword(){
      if (this.password === '') {
        this.passwordEmpty = true
      } else {
        this.passwordEmpty = false
      }
    }
  },
  watch: {
    password: {
      handler: function (newValue) {
        if (newValue === '') {
          this.passwordEmpty = true
        } else {
          this.passwordEmpty = false
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100px;
      height: 100px;
    }
  }

  .account{
    margin-top: 60px;
    margin-bottom: 10px;
    width: 90%;
    height: 55px;
    border: 1px solid #c1c1c1;
    background: white;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: #c1c1c1;
    padding: 0px 8px;
    border-radius: 8px;
    z-index: 10;
  }

  .forgetPassword{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    z-index: 10;
  }

  input{
    margin: 20px 0px;
    width: 90%;
    height: 55px;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    background: white;
  }

  input:focus{
    outline: none;
  }

  input::placeholder {
    font-weight: bold;
  }
}

.errorMsg{
  color: red;
  margin-bottom: 12px;
  width: 90%;
  text-align: center;
  position: relative;
  bottom: 8px;
}
</style>
