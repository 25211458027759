<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="新增車籍資料"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="icon">
            <img src="../../assets/img/profile.svg" alt="" />
          </div>
          <div class="car-card-block">
            <div class="content">
              <div class="input-block">
                <div class="label" @click="openConsole">車牌號碼</div>
                <div class="input-content">
                  <input
                    type="text"
                    class="CarNumberInput"
                    v-model="carNumber"
                  />
                  -
                  <input
                    type="text"
                    class="CarNumberInput"
                    v-model="carNumber1"
                  />
                </div>
              </div>
              <p class="errorMsg" v-if="carNumberEmpty">
                請輸入車牌號碼(左側欄位)
              </p>
              <p class="errorMsg" v-else-if="carNumberTypeErr">
                請輸入正確格式(左側欄位)
              </p>
              <p class="errorMsg" v-if="carNumber1Empty">
                請輸入車牌號碼(右側欄位)
              </p>
              <p class="errorMsg" v-else-if="carNumber1TypeErr">
                請輸入正確格式(右側欄位)
              </p>
              <div class="input-block">
                <div class="label">廠牌選擇</div>
                <select name="vendor" id="vendor" v-model="vendorID">
                  <option value="0" selected>中華三菱車系</option>
                  <option value="1">非中華三菱車系</option>
                </select>
                <template></template>
              </div>
              <template v-if="needEngineNumber">
                <div class="input-block">
                  <div class="label">引擎號碼</div>
                  <div class="input-content">
                    <input
                      v-model="engineNumber"
                      type="text"
                      class="engineNumberInput"
                    />
                  </div>
                </div>
                <p v-if="engineNumberEmpty" class="errorMsg">請輸入引擎號碼</p>
              </template>
            </div>
          </div>
          <div style="width: 100%; height: 80px"></div>
          <mainButton
            style="margin-top: 20px"
            action-name="資料儲存"
            @action="needEngineNumber ? insertWithEng() : insert()"
          ></mainButton>
          <subButton
            style="margin-top: 20px"
            action-name="取消"
            @action="cancel"
          ></subButton>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from '../../components/titleBar'
import bgLayout from '../../components/bgLayout'
import contentBlock from '../../components/contentBlock'
import mainButton from '../../components/mainButton'
import subButton from '../../components/subButton'
import loading from '../../components/loading'
import VConsole from 'vconsole'

export default {
  components: {
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    subButton,
    loading
  },
  data() {
    return {
      isLoading: false,
      needEngineNumber: false,
      carNumber: '',
      carNumber1: '',
      engineNumber: '',
      carNumberEmpty: false,
      carNumber1Empty: false,
      carNumberTypeErr: false,
      carNumber1TypeErr: false,
      engineNumberEmpty: false,
      vendorID: 0
    }
  },
  mounted() {
    this.vConsole = new VConsole()
    this.vConsole.hideSwitch()
  },
  methods: {
    insert() {
      this.checkCarNumber()
      this.checkCarNumber1()

      if (
        [
          this.carNumberEmpty,
          this.carNumberTypeErr,
          this.carNumber1Empty,
          this.carNumber1TypeErr
        ].includes(true)
      )
        return

      this.isLoading = true
      this.axios
        .post(
          process.env.VUE_APP_APIUrl + 'setCar',
          {
            licenseNumber: (
              String(this.carNumber) + String(this.carNumber1)
            ).toUpperCase(),
            vendorID: this.vendorID
          },
          {
            headers: {
              Authorization:
                'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }
        )
        .then((res) => {
          console.log(res.data)
          this.isLoading = false
          if (res.data.code === 20010) {
            // 要求輸入引擎號碼
            this.needEngineNumber = true
            this.checkEngineNumber() // 觸發錯誤訊息顯示
            return
          }

          this.$swal({
            text: '新增成功'
          })
          this.$router.push({ path: '/member/carData' })
        })
        .catch((e) => {
          this.isLoading = false
          this.$swal({
            text: e.response.data.msg
          })
        })
    },
    insertWithEng() {
      this.checkCarNumber()
      this.checkCarNumber1()
      this.checkEngineNumber()

      if (
        [
          this.carNumberEmpty,
          this.carNumberTypeErr,
          this.carNumber1Empty,
          this.carNumber1TypeErr,
          this.engineNumberEmpty
        ].includes(true)
      )
        return

      this.isLoading = true
      this.axios
        .post(
          process.env.VUE_APP_APIUrl + 'setCarWithEng',
          {
            licenseNumber: (
              String(this.carNumber) + String(this.carNumber1)
            ).toUpperCase(),
            vendorID: this.vendorID,
            engineNo: String(this.engineNumber).toUpperCase()
          },
          {
            headers: {
              Authorization:
                'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }
        )
        .then((res) => {
          console.log(res.data)
          this.isLoading = false
          this.$swal({
            text: '新增成功'
          })
          this.$router.push({ path: '/member/carData' })
        })
        .catch((e) => {
          this.isLoading = false
          this.$swal({
            text: e.response.data.msg
          })
        })
    },
    cancel() {
      this.$router.push({ path: '/member/carData' })
    },
    checkCarNumber() {
      this.carNumberEmpty = this.carNumber === ''
      if (!this.carNumberEmpty) {
        this.carNumberTypeErr = !/^.[A-Za-z0-9]+$/.test(this.carNumber)
      }
    },
    checkCarNumber1() {
      this.carNumber1Empty = this.carNumber1 === ''
      if (!this.carNumber1Empty) {
        this.carNumber1TypeErr = !/^.[A-Za-z0-9]+$/.test(this.carNumber1)
      }
    },
    checkEngineNumber() {
      this.engineNumberEmpty = this.engineNumber === ''
    },
    openConsole() {
      this.vConsole.showSwitch()
    }
  },
  watch: {
    carNumber: function () {
      this.checkCarNumber()
    },
    carNumber1: function () {
      this.checkCarNumber1()
    },
    engineNumber: function () {
      this.checkEngineNumber()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 40px;
    img {
      width: 100px;
      height: 100px;
      transform: scale(2);
    }
  }
}

.car-card-block {
  display: inline-flex;
  z-index: 10;
  width: 90%;
  margin: 0 auto;
  .content {
    width: 90%;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    padding: 12px;
    margin: 0 auto;

    .input-block {
      margin-top: 12px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .label {
        font-weight: bold;
        font-size: 14px;
      }

      select {
        width: 70%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #c1c1c1;
        text-align: center !important;
        margin-top: 8px;
        background: #fff;
        margin-left: 8px;
        color: black !important;
      }

      .input-content {
        margin-left: 8px;
        .CarNumberInput {
          border: 1px solid #c1c1c1;
          border-radius: 8px;
          width: 80px;
          height: 40px;
          text-align: center;

          @media screen and (max-width: 376px) {
            width: 77px;
          }
        }

        .engineNumberInput {
          border: 1px solid #c1c1c1;
          border-radius: 8px;
          width: 180px;
          height: 40px;
          text-align: center;
        }

        input:focus {
          outline: none;
        }
      }
    }
  }
}

.errorMsg {
  color: red;
  margin-top: 12px;
  width: 100%;
  text-align: right;
  position: relative;
  bottom: 8px;
}
</style>
