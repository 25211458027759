<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="會員登入"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="icon">
            <img src="../../assets/img/profile.svg" alt="">
          </div>
          <input type="phone" placeholder="請輸入手機號碼" v-model="phone">
          <p class="errorMsg" v-if="phoneEmpty">請輸入手機</p>
          <p class="errorMsg" v-if="phoneType">請確認手機格式</p>
          <mainButton action-name="會員建立/登入"  @action="memberSearch"></mainButton>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import loading from "../../components/loading";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    loading
  },
  data(){
    return{
      phone: '',
      phoneEmpty: false,
      phoneType: false,
      isLoading: false
    }
  },
  methods:{
    memberSearch(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'checkMember'
      let data = {
        "mobile": this.phone
        // mobile: '0932274799'
      }
      this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        if(res.data.data.IsIOV){
          window.localStorage.setItem('phone', this.phone)
          this.isLoading = false
          this.$router.push({path : '/member/memberBinding'})
        }
      })
      .catch(e =>{
        this.isLoading = false
        if(e.response.data.msg === '非IOV會員 !!'){
          window.localStorage.setItem('phone', this.phone)
          this.$router.push({path : '/member/smsCheck'})
        }
      })

    },
    checkPhone(){
      if(this.phone === ''){
        this.phoneEmpty = true
      }else{
        this.phoneEmpty = false
      }
    },
  },
  watch : {
    phone:{
      handler: function(newValue){
        if( newValue == ''){
          this.phoneType = false
          this.phoneEmpty = true
        }else{
          this.phoneEmpty = false
          let regex = /^09[0-9]{8}$/;
          if (!regex.test(this.phone)){
            this.phoneType = true
          }else{
            this.phoneType = false
          }
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    img{
      width: 100px;
      height: 100px;
      transform: scale(2);
    }
  }

  input{
    margin: 20px 0px;
    width: 90%;
    height: 55px;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    background: white;
  }

  input:focus{
    outline: none;
  }

  input::placeholder {
    font-weight: bold;
  }
}
.errorMsg{
  margin-bottom: 16px;
  color: red
}
</style>