<template>
  <div class="btn-block" @click="$emit('action')">
    {{ actionName }}
  </div>
</template>

<script>
  export default {
    props:[
        'actionName'
    ]
  }
</script>

<style lang="scss" scoped>
  .btn-block{
    background: #C40017;
    color: white;
    width: 90%;
    height: 55px;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
</style>