<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="驗證手機號碼"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="title">
            我們將寄送認證簡訊至您所輸入的手機號碼
          </div>
          <div class="phoneNumber">
            {{phoneText }}
          </div>
          <div class="input-block">
            <input type="number" placeholder="請輸入" v-model="code">
            <span class="label">驗證密碼</span>
          </div>
          <p class="errorMsg" v-if="isCodeEmpty">請輸入驗證密碼</p>
          <mainButton action-name="驗證" @action="smsCheck"></mainButton>
          <div class="hints">
            沒收到簡訊？
          </div>
          <subBotton style="margin-top: 20px;" action-name="重新傳送" @action="sendCode"></subBotton>
          <p>若未收到簡訊可能為設定拒收企業簡訊，請與電信公司聯繫</p>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import subBotton from "../../components/subButton";
import loading from "../../components/loading";
import liff from "@line/liff";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    subBotton,
    loading
  },
  data(){
    return{
      phone: '',
      phoneText: '',
      code: '',
      isLoading: false,
      isCodeEmpty: false
    }
  },
  mounted() {
    let phone = window.localStorage.getItem('phone')
    this.phone = phone
    let phoneArray = phone.split('')
    this.phoneText = phoneArray[0] + phoneArray[1]+ phoneArray[2]+ phoneArray[3]+ '-' +phoneArray[4]+ phoneArray[5]+ phoneArray[6] + '-'+ phoneArray[7]+ phoneArray[8]+ phoneArray[9]
    this.sendCode()
  },
  methods:{
    sendCode(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'authCode'
      let data = {
        "mobile": this.phone,
        "authCode": ""
      }
      this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res)
        this.$swal({
          text: '寄送成功',
        })
        // alert(res.data.data)
        this.isLoading = false
      })
          .catch(e =>{
            console.log(e)
            this.$swal({
              text: '寄送錯誤',
            })

            this.isLoading = false
            liff.closeWindow()
          })
    },
    smsCheck(){
      if(this.code === ''){
        this.isCodeEmpty = true
      }else{
        this.isCodeEmpty = false
        this.isLoading = true
        let url = process.env.VUE_APP_APIUrl + 'authCode'
        let data = {
          "mobile": this.phone,
          "authCode": this.code
        }
        this.axios.post(url, data, {
          headers:{
            Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        }).then( res =>{
          if(res.data.msg === 'success'){
            if(res.data.data.hasDataInTemp){
              this.isLoading = false
              this.$router.push({path: '/member/register', query: { hasData: 'true', t:this.code}})
            }else {
              this.isLoading = false
              this.$router.push({path: '/member/register', query: { hasData: 'false'}})
            }
          }
        })
            .catch(e =>{
              console.log(e)
              if(e.response.data.msg === '認證碼認證失敗 !!'){
                this.$swal({
                  text: '認證碼認證失敗',
                })
                // alert('認證碼認證失敗')
              }
              this.isLoading = false
            })
      }
    },
  },
  watch:{
    code:{
      handler: function(newValue){
        if(newValue === ''){
          this.isCodeEmpty = true
        } else {
          this.isCodeEmpty = false
          let length = newValue.split('')
          if(length.length > 4){
            length.pop()
            this.code = length.join('')
          }
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .title{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .phoneNumber{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

  }

  .hints{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  .input-block{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    .label{
      position: absolute;
      top: 37px;
      left: 30px;
      z-index: 11;
      font-weight: bold;
      color: #c1c1c1;
    }
  }
  input{
    margin: 20px 0px;
    width: 90%;
    height: 55px;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    text-align: right;
    padding-right: 8px;
    z-index: 10;
    background: white;
    padding-left: 30%;
  }

  input:focus{
    outline: none;
  }

  input::placeholder {
    color: #c1c1c1;
    font-weight: bold;
  }

  p{
    font-size: 8px;
    margin-top: 8px;
  }
}

.errorMsg{
  color: red;
  margin-bottom: 12px;
  width: 90%;
  text-align: right;
  position: relative;
  bottom: 8px;
}

</style>
