import { render, staticRenderFns } from "./titleBar.vue?vue&type=template&id=63e1a230&scoped=true&"
import script from "./titleBar.vue?vue&type=script&lang=js&"
export * from "./titleBar.vue?vue&type=script&lang=js&"
import style0 from "./titleBar.vue?vue&type=style&index=0&id=63e1a230&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e1a230",
  null
  
)

export default component.exports