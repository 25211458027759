<template>
  <div class="title-bar">
      {{ titleName}}
  </div>
</template>

<script>
export default {
  props:[
      'titleName'
  ]
}
</script>

<style lang="scss" scoped>
.title-bar{
  background: rgba(53,53,53,0.95);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
</style>