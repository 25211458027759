<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="會員資料"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="title">
            基本資料
          </div>
          <div class="form">
            <div class="input-block" style="margin-top: 20px">
              <div class="label">姓名</div>
              <input type="text" v-model="name" :disabled="!canEdit">
            </div>
            <p class="errorMsg" v-if="nameEmpty">請輸入姓名</p>
            <div class="radio-block">
              <div class="label">性別</div>
              <input type="radio" id="M" value="1" v-model="gender" :disabled="!canEdit"/>
              <label for="M">男</label>
              <input type="radio" id="F" value="0" v-model="gender" :disabled="!canEdit"/>
              <label for="F">女</label>
            </div>
            <p class="errorMsg" v-if="genderEmpty">請選擇性別</p>
<!--            <div class="input-block">-->
<!--              <div class="label">身分證字號</div>-->
<!--              <input type="text" v-model="idenNumber" :disabled="!canEdit">-->
<!--            </div>-->
<!--            <p class="errorMsg" v-if="IDEmpty">請輸入身分證字號</p>-->
<!--            <p class="errorMsg" v-if="IDType">請輸入正確格式</p>-->
            <div class="input-block">
              <div class="label">生日</div>
              <input type="date" v-model="birthday" :disabled="!canEdit">
            </div>
            <p class="errorMsg" v-if="birthdayEmpty">請選擇生日</p>
            <div class="input-block">
              <div class="label">Email <span style="font-size: 7px !important;margin-left: 4px;">(選填)</span></div>
              <input type="text" v-model="email" :disabled="!canEdit">
            </div>
<!--            <p class="errorMsg" v-if="emailEmpty">請輸入Email</p>-->
            <p class="errorMsg" v-if="emailType">請輸入正確格式</p>
          </div>
          <div class="botton-block">
            <mainButtonMin action-name="資料編輯" @action="editor"></mainButtonMin>
            <mainButtonMin action-name="資料儲存" @action="save"></mainButtonMin>
          </div>
          <div class="botton-block">
            <subButtonMin action-name="車籍資料" @action="carData"></subButtonMin>
            <subButtonMin action-name="變更密碼" @action="changePassword"></subButtonMin>
          </div>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import subButtonMin from "../../components/subButtonMin";
import mainButtonMin from "../../components/mainButtonMin";
import loading from "../../components/loading";
import liff from "@line/liff";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    subButtonMin,
    mainButtonMin,
    loading
  },
  data(){
    return{
      agree: false,
      name: '',
      gender: '',
      idenNumber: '',
      birthday: '',
      email: '',
      nameEmpty:false,
      genderEmpty:false,
      IDEmpty:false,
      birthdayEmpty:false,
      emailEmpty:false,
      IDType: false,
      emailType:false,
      canEdit: false,
      isLoading: false

    }
  },
  created() {
    this.fetchUserData()
  },
  methods:{
    async  fetchUserData(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'getMember'
      this.axios.post(url, {}, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res.data.data)
        let userData = res.data.data
        this.name = userData.name
        this.gender = userData.gender
        this.idenNumber = userData.idenNumber
        this.birthday = userData.birthday
        this.email = userData.email
        this.isLoading = false

      })
      .catch(e =>{
        console.log(e)
        this.isLoading = false
        this.$router.push({ path : '/member/phoneCheck'})
      })
    },
    checkName(){
      if(this.name === ''){
        this.nameEmpty = true
      }else{
        this.nameEmpty = false
      }
    },
    checkGender(){
      if(this.gender === ''){
        this.genderEmpty = true
      }else{
        this.genderEmpty = false
      }
    },
    checkID(){
      if(this.idenNumber === ''){
        this.IDEmpty = true
      }else{
        this.IDEmpty = false
      }
    },
    checkBirthday(){
      if(this.birthday === ''){
        this.birthdayEmpty = true
      }else{
        this.birthdayEmpty = false
      }
    },
    checkEmail(){
      if(this.email === ''){
        this.emailEmpty = true
      }else{
        this.emailEmpty = false
      }
    },
    openModal(page){
      console.log(page)
    },
    save(){
      //check
      this.checkName()
      this.checkBirthday()
      // this.checkEmail()
      // this.checkID()
      this.checkGender()

      if(
          this.nameEmpty === false &&
          this.genderEmpty === false &&
          this.birthdayEmpty === false &&
          this.emailType === false
      ){
        this.isLoading = true
        let url = process.env.VUE_APP_APIUrl + 'updateMember'
        let data = {
          "name": this.name,
          "gender": this.gender,
          "birthday": this.birthday,
          "email":this.email
        }
        this.axios.post(url, data, {
          headers:{
            Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        }).then( res =>{
          console.log(res.data)
          this.isLoading = false
          // alert(res.data.data)
          this.$swal({
            text: res.data.data,
            type: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "確定"
          }).then((result) => {
            console.log(result)
            liff.closeWindow()
          })
        })
        .catch(e =>{
          console.log(e)
          this.isLoading = false
          // alert('系統錯誤 請洽客服')
          this.$swal({
            text: '系統錯誤 請洽客服',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "確定"
          }).then((result) => {
            console.log(result)
            liff.closeWindow()
          })
        })
      }
    },
    editor(){
      this.canEdit = true
    },
    changePassword(){
      this.$router.push({path: '/member/changePassword'})
    },
    carData() {
      if (
        new Date().valueOf() <
        new Date(process.env.VUE_APP_CAR_MAINTAIN_END_TIME).valueOf()
      ) {
        this.$swal({
          text: `由於目前系統升級中，暫停車籍綁定服務，預計於11/12(日) ${
            process.env.VUE_APP_CAR_MAINTAIN_END_TIME.split(' ')[1]
          }起恢復服務，造成您的不便深感抱歉。`
        })
        return
      }

      this.$router.push({ path: '/member/carData' })
    }
  },
  watch: {
    name: {
      handler: function (newValue) {
        if (newValue == '') {
          this.nameEmpty = true
        } else {
          this.nameEmpty = false
        }
      },
    },
    gender: {
      handler: function (newValue) {
        if (newValue == '') {
          this.genderEmpty = true
        } else {
          this.genderEmpty = false
        }
      },
    },
    // idenNumber: {
    //   handler: function (newValue) {
    //     if (newValue == '') {
    //       this.IDEmpty = true
    //     } else {
    //       this.IDEmpty = false
    //       let regex = /^[A-Z]{1}[1-2]{1}[0-9]{8}$/;
    //       if (!regex.test(newValue)){
    //         this.IDType = true
    //       }else{
    //         this.IDType = false
    //       }
    //     }
    //   },
    // },
    birthday: {
      handler: function (newValue) {
        if (newValue == '') {
          this.birthdayEmpty = true
        } else {
          this.birthdayEmpty = false
        }
      },
    },
    email: {
      handler: function (newValue) {
        if(newValue !== ''){
          let regex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
          if (!regex.test(newValue)){
            this.emailType = true
          }else{
            this.emailType = false
          }
        } else {
          this.emailType = false
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .title{
    margin-top: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .hints{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  .form{
    width: 90%;

    .input-block{
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 10px 0px;
      label{
        z-index: 10;
      }
      .label{
        width: 30%;
        display: flex;
        //justify-content: space-between;
        font-weight: bold;
        align-items: center;
        font-size: 16px;
      }
      input{
        width: 70%;
        border: 1px solid #c1c1c1;
        height: 40px;
        border-radius: 8px;
        z-index: 10;
        text-align: right;
        padding-right: 8px;
      }
    }

    .radio-block{
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;
      label{
        z-index: 10;
      }
      .label{
        width: 30%;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;
        font-size: 16px;
        z-index: 10;
      }
      input{
        z-index: 10;
        width: 18px;
        height: 18px;
      }
    }
  }



  input:focus{
    outline: none;
  }

  input::placeholder {
    color: #c1c1c1;
    font-weight: bold;
  }

  p{
    font-size: 8px;
    margin-top: 8px;
  }

  .botton-block{
    width: 90%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }

  .check-box-block{
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 12px;
    z-index: 10;
    input{
      width: 18px;
      height: 18px;
    }
    label{
      margin-left: 8px;
    }
  }
}

.errorMsg{
  color: red;
  width: 100%;
  text-align: right;
  margin: 0 auto;
}
</style>
