<template>
  <div style="position: relative">
    <div class="bg">
      <titleBar titleName="預約保修"></titleBar>
      <div class="content-block">
        <div class="content" style="padding-bottom: 24px">
          <div class="subTitle">
            聯絡資料
          </div>
          <div class="form-line">
            <div class="label">
              會員姓名
            </div>
            <div class="input">
              <input type="text" v-model="memberInfo.name">
            </div>
            <div class="errorMsg" v-if="nameError">
              請輸入姓名
            </div>
          </div>
          <div class="form-line">
            <div class="label">
              手機號碼
            </div>
            <div class="input">
              <input type="text"  v-model="memberInfo.phone">
            </div>
            <div class="errorMsg" v-if="phoneError">
              請輸入手機號碼
            </div>
          </div>
          <div class="form-line">
            <div class="label">
              車牌號碼
            </div>
            <div class="input">
              <select name="car" id="car" v-model="car">
                <option value="null" disabled selected>選取車牌</option>
                <option :value="item" v-for="(item) in carList" :key="item" >{{ item }}</option>
              </select>
            </div>
            <p class="hint">
              *若您選不到欲保修的車，請先完成該車的車籍綁訂
            </p>
            <div class="errorMsg" v-if="carError">
              請輸入車牌號碼
            </div>
          </div>
        </div>
      </div>
      <div class="content-block" style="transform: translateY(-40px); z-index: 10">
        <div class="content" style="padding-bottom: 24px">
          <div class="subTitle">
            選擇保養維修組合
          </div>
          <div class="check-line">
            <div class="item">
              <input id="normalRepair" type="checkbox" class="checkBox" >
              <label for="normalRepair">一般維修</label>
            </div>
          </div>
          <div class="check-line">
            <div class="item">
              <input id="Maintenance" type="checkbox" class="checkBox" v-model="isMaintenance">
              <label for="Maintenance">定期保養</label>
            </div>
            <select name="project" id="project" v-model="project" v-if="isMaintenance">
              <option :value="null" disabled selected>選取方案</option>
              <option :value="item.asma_cd" v-for="(item, i) in reserveItemList" :key="i + 's'" >{{ item.asma_desc }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="content-block" style="transform: translateY(-80px); z-index: 10">
        <div class="content" >
          <div class="subTitle">
            選擇保養地點
          </div>
          <div class="locationLine">
            <div class="label">
              地區
            </div>
            <select name="city" id="city" v-model="city">
              <option :value="null" disabled selected>請選擇縣市</option>
              <option :value='i.location' v-for="(i, ind) in cityList" :key="ind + '22'">{{ i.location }}</option>
            </select>
            <div class="label">
              區域
            </div>
            <select name="area" id="area" v-model="area">
              <option :value="null" disabled selected>請選擇區域</option>
              <option :value="i.name" v-for="(i, ind) in areaList" :key="ind + '2'">{{ i.name }}</option>
            </select>
          </div>
          <div class="errorMsg" style="width: 90%;margin: 0px auto 16px auto" v-if="cityError">
            請輸入地區
          </div>
          <div class="errorMsg" style="width: 90%;margin: 0px auto 16px auto" v-if="areaError">
            請輸入區域
          </div>
          <div class="otherLine">
            <div class="label">
              服務廠
            </div>
            <img src="../../assets/img/loading1.svg" alt="" v-if="storeLoading">
            <select name="store" id="store" v-model="store" v-if="!storeLoading">
              <option :value="null" disabled selected>請選擇服務廠</option>
              <option :value="i.sr_cd"  v-for="(i, ind) in storeList" :key="ind + '3'">{{ i.dealername + ' ' + i.fullname}}</option>
            </select>
          </div>
          <div class="errorMsg" style="width: 90%;margin: 0px auto 16px auto"  v-if="storeError">
            請輸入服務廠
          </div>
          <div class="otherLine">
            <div class="label">
              進廠保養日期
            </div>
            <img src="../../assets/img/loading1.svg" v-if="dateLoading" alt="">
            <select name="date" id="date" v-model="date" v-if="!dateLoading">
              <option :value="null" disabled selected>請選擇服務廠日期</option>
              <option :value="item.idxday"  v-for="(item, i) in dateList" :key="i + 'a'">{{  item.idxday }}</option>
            </select>
          </div>
          <div class="errorMsg" style="width: 90%;margin: 0px auto 16px auto"  v-if="dateError">
            請輸入保養日期
          </div>
          <div class="otherLine">
            <div class="label">
              進廠保養時間
            </div>
            <img src="../../assets/img/loading1.svg" v-if="timeLoading" alt="">
            <select name="time" id="time" v-model="time" v-if="!timeLoading">
              <option :value="null" disabled selected>請選擇時間</option>
              <option :value="item.time_slot"  v-for="(item, i) in timeList" :key="i + 'a'">{{  item.time_slot }}</option>
            </select>
          </div>
          <div class="errorMsg" style="width: 90%;margin: 0px auto 16px auto"  v-if="timeError">
            請輸入保養時間
          </div>
          <p class="hints">* 開放十四天內預約;隔日之預約單請於16:00前預約。</p>
          <p class="hints">* 順益、裕益汽車週日暫不開放線上預約，若您需要於周日進場，請改以電話與服務場聯繫。</p>
          <p class="hints">* 如時段可預約車次為0，表示該時段可預約台數已滿，請預約其他時段或電話與保養廠聯繫。</p>
          <mainButton action-name="立即預約" style="margin: 20px 0px; z-index: 10" @action="showAlert"></mainButton>
        </div>
      </div>
      <img src="../../assets/img/bg1.svg" alt="" class="bg-img-top-right">
      <img src="../../assets/img/bg2.svg" alt="" class="bg-img-bottom-left">
    </div>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import loading from '../../components/loading'
import titleBar from "../../components/titleBar";
import mainButton from "../../components/mainButton";
import liff from "@line/liff";

export default {
  components:{
    loading,
    titleBar,
    mainButton
  },
  data(){
    return {
      isLoading: false,
      storeLoading: false,
      dateLoading: false,
      timeLoading: false,
      //error
      nameError: false,
      phoneError: false,
      carError: false,
      cityError: false,
      areaError: false,
      storeError: false,
      dateError: false,
      timeError: false,
      //error close
      carList:[],
      cityList: [],
      areaList: [],
      storeList: [],
      reserveItemList:[],
      dateList:[],
      timeList:[],
      memberInfo:{},
      car: null,
      project: '',
      city: null,
      area: null,
      store: null,
      storeText: null,
      date: null,
      time: null,
      factory_tel: null,
      factory_name: null,
      isMaintenance: false
    }
  },
  created() {
    this.fetchUserData()
    this.fetchInfo()
  },
  watch:{
    isMaintenance:{
      handler: function(newValue) {
        if(newValue === false){
          this.project = null
        }
      }
    },
    city:{
      handler: function(newValue, oldValue) {
        if(newValue === null || newValue === ''){
          this.cityError = true
        }else {
          this.cityError = false
        }
        if(newValue !== null && newValue !== oldValue){
          this.store = null
          this.storeList = []
          this.date = null
          this.dateList = []
          this.time = null
          this.timeList = []
          this.cityList.forEach(i =>{
            if(i.location === newValue){
              this.areaList = i.town
            }
          })
        }
      },
    },
    area:{
      handler: function(newValue, oldValue) {
        if(newValue === null || newValue === ''){
          this.areaError = true
        }else {
          this.areaError = false
        }
        if(newValue !== oldValue && newValue !== null){
          this.store = null
          this.storeList = []
          this.date = null
          this.dateList = []
          this.time = null
          this.timeList = []
          this.fetchStore()
        }
      },
    }
    ,
    store:{
      handler: function(newValue, oldValue) {
        if(newValue === null || newValue === ''){
          this.storeError = true
        }else {
          this.storeError = false
        }
        if(newValue !== oldValue && newValue !== null){
          this.storeList.forEach(i =>{
            if(i.sr_cd === this.store){
              this.storeText =  i.dealername + ' ' + i.fullname
              this.factory_name = i.fullname
              this.factory_tel = i.tel
            }
          })
          this.date = null
          this.dateList = []
          this.time = null
          this.timeList = []
          this.fetchDate()
        }
      },
    },
    date:{
      handler: function(newValue, oldValue) {
        if(newValue === null || newValue === ''){
          this.dateError = true
        }else {
          this.dateError = false
        }
        if(newValue !== null && newValue !== oldValue){
          this.dateError = false
          this.timeLoading = true
          this.time = null
          this.timeList = []
          this.dateList.forEach(i =>{
            if(i.idxday === newValue){
              this.timeList = i.timeDetail
            }
          })
          this.timeLoading = false
        }
      },
    },
    time:{
      handler: function(newValue) {
        if(newValue === null || newValue === ''){
          this.timeError = true
        }else {
          this.timeError = false
        }
      },
    },
    car:{
      handler: function(newValue) {
        if(newValue === null || newValue === ''){
          this.carError = true
        }else {
          this.carError = false
        }
      },
    },
    memberInfo:{
      handler: function(newValue) {
        if(newValue.name === ''){
          this.nameError = true
        } else{
          this.nameError = false
        }
        if(newValue.phone === ''){
          this.phoneError = true
        } else{
          this.phoneError = false
        }
      },
      deep:true
    }
  },
  mounted(){
  },
  methods:{
    submit(){
        this.isLoading = true
        this.recaptcha()
    },
    async recaptcha() {
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/maintain/addReserve'
      let url = process.env.VUE_APP_APIUrl + 'maintain/addReserve'

      let data = {
        "name":this.memberInfo.name,
        "mobile": this.memberInfo.phone,
        "lic_no": this.car,
        "sr_cd": this.store,
        "factory_name": this.factory_name,
        "factory_tel": this.factory_tel,
        "rItemOption": this.project,
        "date":this.date,
        "time":this.time,
      }

      this.axios.post(url, data,{
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      })
      .then(res =>{
        console.log(res.data)
        this.isLoading = false
        liff.closeWindow()
      })
      .catch(e =>{
        console.log(e)
        this.isLoading = false
        this.$swal({
          text: e.response.data.msg,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "確定"
        }).then((result) => {
          console.log(result)
          liff.closeWindow()
        })
      })
    },
    fetchDate(){
      this.dateLoading = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/maintain/date'
      let url = process.env.VUE_APP_APIUrl + 'maintain/date'
      let data = {
        "sr_cd": this.store
      }
      this.axios.post(url, data,{
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      })
          .then(res =>{
            console.log(res.data)
            if(res.data.msg === 'success'){
              this.dateList = res.data.data
            }
            this.dateLoading = false

          })
          .catch(e =>{
            this.$swal({
              text: e.response.data.msg,
            })
            this.dateLoading = false
            console.log(e)
          })
    },
    fetchStore(){
      this.storeLoading = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'maintain/factory'
      let url = process.env.VUE_APP_APIUrl + 'maintain/factory'
      let data = {
        "location": this.city,
          "town": this.area
      }
      this.axios.post(url, data,{
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      })
        .then(res =>{
          console.log(res.data)
          if(res.data.msg === 'success'){
            this.storeList = res.data.data
          }
          this.storeLoading = false

        })
        .catch(e =>{
          this.$swal({
            text: e.response.data.msg,
          })
          this.storeLoading = false
          console.log(e)
        })
    },
    async  fetchUserData(){
      let url = process.env.VUE_APP_APIUrl + 'getMember'
      this.axios.post(url, {}, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res.data.data)
      })
      .catch(e =>{
        console.log(e)
        this.$router.push({ path : '/member/register'})
      })
    },
    async fetchInfo(){
      this.isLoading= true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'maintain/info'
      let url = process.env.VUE_APP_APIUrl + 'maintain/info'
      await this.axios.get(url, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      })
      .then(res =>{
        console.log(res.data)
        if(res.data.msg === 'success'){
          this.cityList = res.data.data.locationList
          this.memberInfo = res.data.data.memberInfo
          this.reserveItemList = res.data.data.reserveItemList
          this.carList = []
          res.data.data.memberInfo.scooters.forEach(item =>{
            this.carList.push(item.licenseNumber)
          })
          this.isLoading= false
        }
      })
      .catch(e =>{
        this.$swal({
          text: e.response.data.msg,
        })
        this.isLoading= false
        console.log(e)
      })
    },
    showAlert(){
      if (this.memberInfo.name === ''){
        this.nameError = true
      }
      if (this.memberInfo.phone === ''){
        this.phoneError = true
      }
      if (this.car === null){
        this.carError = true
      }
      if(this.city === null){
        this.cityError = true
      }
      if(this.area === null){
        this.areaError = true
      }
      if(this.store === null){
        this.storeError = true
      }
      if(this.date === null){
        this.dateError = true
      }
      if(this.time === null){
        this.timeError = true
      }

      if(this.nameError === false &&
          this.nameError === false &&
          this.phoneError === false &&
          this.carError === false &&
          this.cityError === false &&
          this.areaError === false &&
          this.storeError === false &&
          this.dateError === false &&
          this.timeError === false
      ) {
        this.$swal({
          html: `
        <div class="test" style="font-weight: bold; text-align: left">請再次確認下方資訊</div><ul style="text-align: left"><li>預約車號： ${ this.car}</li><li>預約時間：  ${this.date} ${this.time}</li><li>服務廠： ${this.storeText}</li><li>電話： ${this.factory_tel}</li></ul>
        `,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#C40017",
          confirmButtonText: "確認送出",
          cancelButtonText: "取消"
        }).then((result) => {
          if (result.value) {
            this.submit()
          }
        });
      }else {
        this.$swal({
          text: '您的預約資料尚未填寫完成，請再次確認！',
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped>

.content-block{
  width: 95%;
  margin: 16px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px -4px 0px #D9363E;
}

.bg{
  background: #EFEFEE;
  position: relative;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  .bg-img-top-right{
    position: absolute;
    top: 0;
    right: 0;
  }
  .bg-img-bottom-left{
    position: absolute;
    bottom: 0;
    left: 0;
  }

}

  .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .subTitle{
      width: 90%;
      margin: 12px auto;
      font-size: 18px;
      font-weight: bold;
    }
    .locationLine{
      width: 90%;
      margin: 12px auto;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      .label{
        width: 25%;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
      }

      input:focus, select:focus{
        outline: none;
      }

      select{
        width: 70%;
        height: 40px;
        border: 1px solid #c1c1c1;
        border-radius: 8px;
        text-align: center;
        background: white ;
        margin: 0px 4px;
      }
    }
    .hints{
      width: 90%;
      margin: 0px auto;
      font-size: 8px;
      font-weight: bold;
    }
    .otherLine{
      width: 90%;
      margin: 12px auto;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;

      .label{
        width: 35%;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
      }

      input:focus, select:focus{
        outline: none;
      }

      select{
        width: 65%;
        height: 40px;
        border: 1px solid #c1c1c1;
        border-radius: 8px;
        text-align: center;
        background: white ;
        margin: 0px 4px;
      }
    }
    .check-line{
      width: 90%;
      margin: 12px auto;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      .item{
        display: inline-flex;
        align-items: center;

        input[type = 'checkbox']{
          width: 20px;
          height: 20px;
        }
        label{
          letter-spacing: 2px;
          font-weight: bold;
          font-size: 16px;
          margin-left: 8px;
        }
      }

      input:focus, select:focus{
        outline: none;
      }

      select{
        width: 60%;
        height: 32px;
        border: 1px solid #c1c1c1;
        border-radius: 8px;
        text-align: center;
      }
    }
    .form-line{
      width: 90%;
      margin: 12px auto;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      .label{
        width: 28%;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        letter-spacing: 2px;
      }
      .input{
        width: 70%;
        input{
          width: 100%;
          height: 40px;
          border: 1px solid #c1c1c1;
          border-radius: 8px;
          padding-left: 8px;
          text-align: center;
          background: #fff;
        }
        input:focus, select:focus{
          outline: none;
        }
        select{
          width: 100%;
          height: 40px;
          border: 1px solid #c1c1c1;
          border-radius: 8px;
          text-align: center;
        }
      }
    }
  }

  .hr{
    width: 90%;
    margin: 12px auto;
    height: 1px;
    background: #c1c1c1;
  }
  .errorMsg{
    width: 100%;
    margin-top: 8px;
    color: #C40017;
    font-size: 16px;
    text-align: right;
  }

  .hint{
    width: 100%;
    font-size: 8px;
    text-align: right;
    margin-top: 8px;
  }
</style>
