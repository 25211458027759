<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="車籍資料"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="icon">
            <img src="../../assets/img/profile.svg" alt="">
          </div>
          <div class="hint">
            您目前已綁定 {{ carData.length }} 台車
          </div>
          <div v-if="carData == null" style="height: 200px;width: 100%;"></div>
          <div class="scroll">
            <carCard style="margin: 10px 0px;" v-for="(item,index) in carData" :key="index" @remove="removeCar" :carData="item"></carCard>
          </div>
          <mainButton v-if="carData.length < 30" style="margin-top: 20px;" action-name="新增車籍資料" @action="insert"></mainButton>
          <div class="disableBtn" v-if="carData.length >= 30">
            綁定已達上限
          </div>
          <subButton style="margin: 20px 0px;" action-name="返回會員資料" @action="cancel"></subButton>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import subButton from "../../components/subButton";
import carCard from "../../components/carCard";
import loading from "../../components/loading";
export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    subButton,
    carCard,
    loading
  },
  data(){
    return{
      isLoading: false,
      carData: [
      ],
      carDataTest: [
        {
          carNumber: 12341234,
          vendorID: 0
        },
        {
          carNumber: 12341234,
          vendorID: 1
        },
      ]
    }
  },
  created() {
    if (
      new Date().valueOf() <
      new Date(process.env.VUE_APP_CAR_MAINTAIN_END_TIME).valueOf()
    ) {
      this.$swal({
        text: `由於目前系統升級中，暫停車籍綁定服務，預計於11/12(日) ${
          process.env.VUE_APP_CAR_MAINTAIN_END_TIME.split(' ')[1]
        }起恢復服務，造成您的不便深感抱歉。`
      }).then(() => {
        this.$router.push({ path: '/member/memberData' })
      })
      return
    }

    this.fetchUserData()
  },
  methods:{
    async  fetchUserData(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'getMember'
      this.axios.post(url, {}, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res.data.data)
        let carData = res.data.data.scooters
        if (carData === null){
          this.carData =[]
        }else{
          this.carData= []
          carData.forEach(item =>{
            let data = {
              carNumber: item.licenseNumber,
              vendorID: item.verdorID
            }
            this.carData.push(data)
          })
        }
        this.isLoading = false

      })
      .catch(e =>{
        console.log(e)
        this.isLoading = false
      })
    },
    insert(){
      this.$router.push({path: '/member/addCarData'})
    },
    cancel(){
      this.$router.push({path: '/member/memberData'})
    },
    removeCar(car){
      this.$swal({
        text: "確認刪除車輛資料?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確定"
      }).then((result) => {
        if (result.value) {
          this.isLoading = true
          let url = process.env.VUE_APP_APIUrl + 'deleteCar'
          let data = {
            "licenseNumber": String(car.carNumber),
          }
          this.axios.post(url, data, {
            headers:{
              Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }).then( res =>{
            console.log(res.data)
            this.$swal({
              text: res.data.data,
            })
            this.fetchUserData()
          })
          .catch(e =>{
            this.isLoading = false
            console.log(e.response.data.msg)
            this.$swal({
              text: '系統錯誤 請洽客服',
            })
          })
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 40px;
    img{
      width: 100px;
      height: 100px;
      transform: scale(2);
    }
  }

  .hint{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .scroll{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 300px;
    overflow: scroll;
  }
}

.disableBtn{
  width: 90%;
  margin: 10px auto;
  height: 55px;
  background: #c1c1c1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 10;
}

</style>
