<template>
  <div style="position: relative">
    <div class="bg">
      <titleBar :titleName="pageName"></titleBar>
      <div class="X" v-if="openModal" @click="closeModal">X</div>
      <div class="content-block" v-if="!openModal">
        <div class="content" style="padding-bottom: 24px">
          <div class="controller">
            <div class="no">1</div>
            <div class="sub-title">請問貴賓大名</div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="text" v-model="name" />
            </div>
            <p class="errorMsg" v-if="nameEmpty">請輸入姓名</p>
            <p class="errorMsg" v-else-if="nameError">
              請填寫「中文姓名」全名（最多五個字）
            </p>
          </div>
          <div class="controller">
            <div class="no">2</div>
            <div class="sub-title">請問貴賓手機號碼</div>
            <div class="form-controller">
              <div class="space"></div>
              <input
                type="text"
                v-model="phone"
                inputmode="numeric"
                maxLength="10"
              />
            </div>
            <div class="errorMsg" v-if="phoneError">請確認手機格式</div>
          </div>
          <div class="controller">
            <div class="no">3</div>
            <div class="sub-title">保養廠</div>
            <div class="form-controller">
              <div class="space"></div>
              <select
                name="area"
                id=""
                v-model="salesNode"
                v-if="!salesNodeLoading"
              >
                <option :value="null" selected disabled>請選擇保養廠</option>
                <option
                  v-for="node in salesNodeOption"
                  :key="node.node_tax_code"
                  :value="node.node_tax_code"
                >
                  {{ node.node_name }}
                </option>
              </select>
              <img
                v-if="salesNodeLoading"
                src="../../assets/img/loading1.svg"
                alt=""
              />
            </div>
            <p class="errorMsg" v-if="salesNodeEmpty">請選擇保養廠</p>
          </div>
          <div class="controller">
            <div class="no">4</div>
            <div class="sub-title">業代人員</div>
            <div class="form-controller">
              <div class="space"></div>
              <select
                v-if="!salesLoading && salesNode"
                name="salesperson"
                id=""
                v-model="salesperson"
              >
                <option :value="null" selected disabled>請選擇業代人員</option>
                <option
                  v-for="person in saleList"
                  :value="person.salesman_code"
                  :key="person.salesman_code"
                >
                  {{ person.salesman_name }}
                </option>
              </select>
              <img
                v-if="salesLoading"
                src="../../assets/img/loading1.svg"
                alt=""
              />
            </div>
            <p class="errorMsg" v-if="salespersonEmpty">請選擇業代人員</p>
          </div>
          <div class="controller">
            <div class="no">5</div>
            <div class="sub-title">
              您有興趣的車款<span style="font-size: 10px; padding-left: 4px">
                (可複選，最多不超過兩款)
              </span>
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <div class="option-block">
                <div
                  class="half"
                  v-for="(i, ind) in carTypeOptions"
                  :key="ind + '123'"
                >
                  <input
                    type="checkbox"
                    :id="'option' + ind"
                    v-model="i.checked"
                  />
                  <label :for="'option' + ind">{{ i.text }}</label>
                </div>
              </div>
            </div>
            <div class="errorMsg" v-if="carTypeError">車款 最多選兩個</div>
            <p class="errorMsg" v-if="carTypeEmpty">請選擇車款</p>
          </div>
          <div class="controller">
            <div class="form-controller">
              <div class="space"></div>
              <input type="checkbox" id="agree" v-model="agree" />
              <label for="agree" style="letter-spacing: 2px; z-index: 10">
                我同意上述
                <span style="text-decoration: underline" @click="openPpModal">
                  個資政策及其條款
                </span>
              </label>
            </div>
          </div>
          <mainButton
            action-name="送出問卷"
            style="margin: 20px 0px; z-index: 10"
            @action="submitForm"
          ></mainButton>
        </div>
      </div>
      <div
        class="content-block"
        v-if="openModal"
        style="min-height: 90vh !important"
      >
        <div class="content" style="min-height: 45vh !important">
          <p
            style="
              font-weight: bold;
              font-size: 16px;
              margin: 24px auto 16px auto;
              width: 90%;
            "
          >
            依據個人資料保護法等相關規定，以下告知事項請 台端詳閱：
          </p>
          <p style="font-size: 14px; margin: 0px auto; width: 90%">
            參加本活動前，請務必詳閱本活動辦法內容，當您透過本活動網站輸入個人資料參與活動時表示已同意下述蒐集個人資料告知事項與同意書之內容。
          </p>
          <p style="font-size: 14px; margin: 2px auto; width: 90%">
            本人同意中華汽車工業股份有限公司(以下簡稱CMC)，依個人資料保護法規定，得為行銷及客戶服務管理之目的，於中華民國蒐集、處理及利用本人之上網登錄之個人資料，包含本人之姓名、聯絡電話等，並針對本人的需求提供個人化的行銷或服務內容。CMC將以包含但不限於簡訊、電話等聯絡方式，使本人知悉產品銷售、售後服務等權益事項、優惠、贈品或相關資訊及廣告。本人依照個人資料保護法得隨時請求CMC查閱、給予複本、或補正本人之個人資料，亦得隨時洽CMC之客服專線(0800--030-580)
            拒絕CMC繼續蒐集、處理、利用或刪除本人之個人資料；本人同意由CMC依本同意書之約定持續蒐集、處理、利用本人所提供之個人資料，截至本人主動請求CMC刪除、停止處理或利用該個人資料之日或CMC執行業務所必需之保存期間止，CMC始終止其個人資料之蒐集、處理及利用。本人並同意CMC得將本人之個人資料提供予相對人之關係企業，於與前述目的類似之目的範圍內，蒐集、處理及利用。本人得自由選擇是否提供相關個人資料，惟若本人不提供時，本人瞭解將無法參加本活動。
          </p>
        </div>
      </div>

      <img src="../../assets/img/bg1.svg" alt="" class="bg-img-top-right" />
      <img
        src="../../assets/img/bg2.svg"
        alt=""
        class="bg-img-bottom-left"
        @click="openConsole"
      />
    </div>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import loading from '../../components/loading'
import titleBar from '../../components/titleBar'
import mainButton from '../../components/mainButton'
import liff from '@line/liff'
import VConsole from 'vconsole'

export default {
  components: {
    loading,
    titleBar,
    mainButton
  },
  data() {
    return {
      //system
      isLoading: false,
      openModal: false,
      salesNodeLoading: true,
      salesLoading: false,
      nameEmpty: false,
      nameError: false,
      phoneError: false,
      carTypeEmpty: false,
      carTypeError: false,
      salesNodeEmpty: false,
      salespersonEmpty: false,
      pageName: '填中華三菱專業諮詢問券 月月抽200Line Points',
      //data
      name: '',
      phone: '',
      salesNode: '',
      salesperson: '',
      agree: false,
      carTypeOptions: [
        {
          text: 'A180',
          value: 'A180',
          checked: false
        },
        {
          text: 'A190',
          value: 'A190',
          checked: false
        },
        {
          text: 'A210',
          value: 'A210',
          checked: false
        },
        {
          text: 'COLT PLUS',
          value: 'COLT PLUS',
          checked: false
        },
        {
          text: 'GRAND LANCER',
          value: 'GRAND LANCER',
          checked: false
        },
        {
          text: 'OUTLANDER',
          value: 'OUTLANDER',
          checked: false
        },
        {
          text: 'ZINGER',
          value: 'ZINGER',
          checked: false
        },
        {
          text: 'ZINGER PICKUP',
          value: 'ZINGER PICKUP',
          checked: false
        },
        {
          text: '中華堅兵',
          value: '中華堅兵',
          checked: false
        },
        {
          text: '中華菱利電動車E300貨車',
          value: '中華菱利電動車E300貨車',
          checked: false
        },
        {
          text: '中華菱利電動車E300廂車',
          value: '中華菱利電動車E300廂車',
          checked: false
        },
        {
          text: '得利卡貨車',
          value: '得利卡貨車',
          checked: false
        },
        {
          text: '得利卡廂車',
          value: '得利卡廂車',
          checked: false
        }
      ],
      salesNodeOption: [],
      saleList: [],
      lat: '',
      lon: '',
      vConsole: null
    }
  },
  async mounted() {
    this.vConsole = new VConsole()
    this.vConsole.hideSwitch()

    this.$swal({
      html: `<div>為提供您最佳服務，將判斷您所屬位置後即可填寫問卷及抽獎!<br><span style="color:red;font-size: 12px">*如不同意，將無法參加活動，要注意喔!</span></div>`
    }).then((res) => {
      console.log('get geo swal res:', res)
      if (!res.value) return
      this.getGeoLocation()
        .then((res) => {
          console.log('getGeoLocation res', res)
          this.lon = res.lon
          this.lat = res.lat
          this.fetchSalesNode()
        })
        .catch((err) => {
          console.log('getGeoLocation err', err)
          liff.closeWindow()
        })
    })
  },
  watch: {
    name: {
      handler: function () {
        this.checkName()
      }
    },
    phone: {
      handler: function () {
        this.checkPhone()
      }
    },
    salesNode: {
      handler: function (newValue) {
        if (!newValue) return
        this.salesNodeEmpty = false
        this.salesperson = null
        this.fetchSaleList()
      }
    },
    salesperson: {
      handler: function () {
        this.salespersonEmpty = !this.salesperson
      }
    },
    carTypeOptions: {
      handler: function () {
        this.checkCarType()
      },
      deep: true
    }
  },
  methods: {
    checkName() {
      this.nameEmpty = this.name === ''
      this.nameError = !/^[\u4e00-\u9fa5]{1,5}$/.test(this.name)
    },
    checkPhone() {
      this.phoneError = !/^09\d{8}$/.test(this.phone)
    },
    checkCarType() {
      const checkedItem = this.carTypeOptions.filter((item) => item.checked)
      this.carTypeEmpty = checkedItem.length === 0
      this.carTypeError = checkedItem.length > 2
    },
    checkRequiredError() {
      this.salesNodeEmpty = !this.salesNode
      this.salespersonEmpty = !this.salesperson
    },
    getGeoLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              resolve({
                lat: position.coords.latitude,
                lon: position.coords.longitude
              })
            },
            function (e) {
              reject(e)
            }
          )
        } else {
          this.$swal({
            text: '您的瀏覽器不支援 顯示地理位置 API ，請使用其它瀏覽器開啟 這個網址'
          })
          reject('browser not support')
        }
      })
    },
    submitForm() {
      if (!this.agree) {
        this.$swal({
          text: '請同意條款'
        })
        return
      }

      this.checkName()
      this.checkPhone()
      this.checkCarType()
      this.checkRequiredError()

      if (
        [
          this.nameEmpty,
          this.nameError,
          this.phoneError,
          this.carTypeEmpty,
          this.carTypeError,
          this.salesNodeEmpty,
          this.salespersonEmpty
        ].includes(true)
      ) {
        this.$swal({
          text: '請檢查輸入欄位格式正確，不可為空白'
        })
        return
      }

      this.isLoading = true

      const matchNode = this.salesNodeOption.find(
        (el) => el.node_tax_code === this.salesNode
      )
      const matchSale = this.saleList.find(
        (el) => el.salesman_code === this.salesperson
      )

      this.sendFormData({
        name: this.name,
        mobile: this.phone,
        node_tax_code: this.salesNode,
        node_name: matchNode.node_name,
        salesman_code: this.salesperson,
        salesman_name: matchSale.salesman_name,
        car_types: this.carTypeOptions
          .filter((el) => el.checked)
          .map((el) => el.value),
        is_agreed: 1,
        longitude: `${this.lon}`,
        latitude: `${this.lat}`
      })
    },
    async sendFormData(data) {
      this.isLoading = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'saveInVisitorAnswer'
      let url = process.env.VUE_APP_APIUrl + 'saveInVisitorAnswer3'
      await this.axios
        .post(url, data, {
          headers: {
            Authorization:
              'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        })
        .then((res) => {
          console.log('sendFormData res', res)
          this.isLoading = false
          this.$swal({
            text: '感謝您填寫問卷並獲得抽200點LINE POINTS資格，後續將由專屬銷售專員與您聯絡接洽！感謝！'
          }).then(() => {
            liff.closeWindow()
          })
        })
        .catch((e) => {
          console.log('sendFormData err', e)
          this.isLoading = false
          if (e.response.data.msg.includes('required')) {
            this.$swal({
              text: '請檢查輸入欄位格式正確，不可為空白'
            })
            this.isLoading = false
            return
          }
          this.$swal({
            text: e.response.data.msg,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonColor: '#3085d6',
            confirmButtonText: '確定'
          }).then((result) => {
            console.log('sendFormData err swal res', result)
            liff.closeWindow()
          })
          this.isLoading = false
        })
    },
    async fetchSalesNode() {
      this.salesNodeLoading = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'getFolkSalesNodeList'
      const url = process.env.VUE_APP_APIUrl + 'getFolkSalesNodeList'
      const data = {
        longitude: this.lon,
        latitude: this.lat
      }
      await this.axios
        .post(url, data, {
          headers: {
            Authorization:
              'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        })
        .then((res) => {
          if (res.data.msg === 'success') {
            console.log('fetchSalesNode res', res.data.data)
            this.salesNodeOption = res.data.data.salsesNodes
          }
          this.salesNodeLoading = false
        })
        .catch((e) => {
          console.log('fetchSalesNode err', e)
          this.$swal({
            text: e.response.data.msg
          })
          this.salesNodeLoading = false
        })
    },
    async fetchSaleList() {
      this.salesLoading = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'getFolkSalesmanList'
      const url = process.env.VUE_APP_APIUrl + 'getFolkSalesmanList'
      const data = { node_tax_code: this.salesNode }
      await this.axios
        .post(url, data, {
          headers: {
            Authorization:
              'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        })
        .then((res) => {
          if (res.data.msg === 'success') {
            console.log('fetchSaleList res', res.data.data)
            this.saleList = res.data.data.salsesmen
          }
          this.salesLoading = false
        })
        .catch((e) => {
          console.log('fetchSaleList err', e)
          this.$swal({
            text: e.response.data.msg
          })
          this.salesLoading = false
        })
    },
    closeModal() {
      this.openModal = false
      this.pageName = this.$options.data().pageName
    },
    openPpModal() {
      this.openModal = true
      this.pageName = '個資同意'
    },
    openConsole() {
      this.vConsole.showSwitch()
    }
  },
  beforeDestroy() {
    this.vConsole.destroy()
  }
}
</script>

<style lang="scss" scoped>
input {
  color: black !important;
}

.w-full {
  width: 100% !important;
}
.content-block {
  width: 95%;
  margin: 16px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px -4px 0px #d9363e;
  min-height: 100vh;
}

.bg {
  background: #efefee;
  position: relative;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  .bg-img-top-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .bg-img-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .controller {
    display: inline-flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 12px auto;
    .no {
      width: 15%;
      font-size: 20px;
      font-weight: bold;
      color: gray;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sub-title {
      width: 85%;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    .form-controller {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      z-index: 10;
      .space {
        width: 15%;
        height: 5px;
      }
      .option-block {
        width: 85%;
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 8px;
        .half {
          width: 50%;
          height: 50px;
          input[type='checkbox'] {
            width: 16px;
            height: 16px;
          }
          label {
            font-size: 15px;
            margin: 4px 6px;
            font-weight: bold;
          }
        }
      }
      input[type='checkbox'] {
        width: 16px;
        height: 16px;
      }
      input[type='radio'] {
        width: 16px;
        height: 16px;
      }
      input[type='text'] {
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        margin-top: 6px;
        text-align: center;
        background: #fff;
      }
      input[type='date'] {
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        margin-top: 6px;
        text-align: center;
        background: #fff;
      }
      select {
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        text-align: center;
        margin-top: 8px;
        background: #fff;
      }
      input:focus,
      select:focus {
        outline: none;
      }
      label {
        font-size: 15px;
        margin: 4px 6px;
        font-weight: bold;
      }
    }
  }
}

input {
  color: black !important;
  text-align: center !important;
}

.X {
  position: absolute;
  top: 6px;
  left: 22px;
  font-size: 32px;
  color: white;
  z-index: 10;
}

.errorMsg {
  width: 100%;
  margin-top: 8px;
  color: #c40017;
  font-size: 16px;
  text-align: right;
}
</style>
