<template>
  <div style="position: relative">
    <bgLayout>
      <titleBar titleName="忘記密碼"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="title">
            我們已寄發重設密碼驗證碼至您註冊的手機號碼
          </div>
          <div class="form">
            <div class="input-block" style="margin-top: 20px">
              <div class="label">簡訊碼</div>
              <input type="text" v-model="code">
            </div>
            <p class="errorMsg" v-if="isCodeEmpty">請輸入驗證密碼</p>
            <div class="input-block">
              <div class="label">設定新密碼</div>
              <input type="password"  v-model="password">
            </div>
            <p class="errorMsg" v-if="passwordError">限6-30字元，不能與手機號碼相同</p>
            <div class="input-block">
              <div class="label">確認新密碼</div>
              <input type="password"  v-model="rePassword">
            </div>
            <p class="errorMsg"  v-if="rePasswordError">限6-30字元，不能與手機號碼相同</p>
            <p class="errorMsg"  v-if="sameError">確認密碼不同</p>
          </div>
          <mainButton style="margin-top: 30px; z-index: 10;" action-name="重設密碼" @action="smsCheck"></mainButton>
          <div class="hints">
            沒收到簡訊？
          </div>
          <subBotton style="margin-top: 20px;" action-name="重新傳送" @action="sendCode"></subBotton>
          <p>若未收到簡訊可能為設定拒收企業簡訊，請與電信公司聯繫</p>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import subBotton from "../../components/subButton";
import liff from "@line/liff";
import loading from "../../components/loading";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    subBotton,
    loading

  },
  data(){
    return{
      phone: '',
      isLoading: false,
      code:'',
      isCodeEmpty: false,
      password: '',
      rePassword: '',
      passwordError: false,
      rePasswordError: false,
      sameError: false
    }
  },
  mounted() {
    let phone = window.localStorage.getItem('phone')
    this.phone = phone
    this.sendCode()
  },
  methods:{
    sendCode(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'forgetPwAuthCode'
      let data = {
        "mobile": this.phone,
      }
      this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res)
        // alert(res.data.data)
        this.$swal({
          text: res.data.data,
        })
        this.isLoading = false
      })
        .catch(e =>{
          console.log(e)
          // alert('寄送錯誤')
          this.$swal({
            text: '寄送錯誤',
          })
          this.isLoading = false
          liff.closeWindow()
        })
    },
    passwordCheck(){
      if( this.password === ''){
        this.passwordError = true
      }else{
        this.passwordError = false
      }
    },
    rePasswordCheck(){
      if( this.rePassword === ''){
        this.rePasswordError = true
      }else{
        this.rePasswordError = false
      }
    },

    smsCheck(){
      this.passwordCheck()
      this.rePasswordCheck()
      this.codeCheck()
      if(
          this.isCodeEmpty === false &&
          this.passwordError === false &&
          this.rePasswordError === false &&
          this.sameError === false
      ){
        this.changePassword()

      }
    },
    codeCheck(){
      if(this.code === ''){
        this.isCodeEmpty = true
      }else{
        this.isCodeEmpty = false
      }
    },
    changePassword(){
      this.isLoading = true
      let url = process.env.VUE_APP_APIUrl + 'resetPassword'
      let data = {
        "mobile": this.phone,
        "newPassword": this.password,
        "authCode": this.code
      }
      this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res)
        // alert(res.data.data)
        this.$swal({
          text: res.data.data,
        })
        this.isLoading = false
        this.$router.push({path: '/member/memberBinding'})
      })
      .catch(e =>{
        console.log(e)
        // alert('系統錯誤 請洽客服')
        this.$swal({
          text: '系統錯誤 請洽客服',
        })
        this.isLoading = false
        liff.closeWindow()
      })
    }
  },
  watch:{
    code:{
      handler: function(newValue){
        if(newValue === ''){
          this.isCodeEmpty = true
        } else {
          this.isCodeEmpty = false
          let length = newValue.split('')
          if(length.length > 4){
            length.pop()
            this.code = length.join('')
          }
        }
      },
    },
    password:{
      handler: function(newValue){
        if( newValue == ''){
          this.passwordError = true
        }else{
          let arr = newValue.split('')
          if(arr.length < 6){
            this.passwordError = true
          }else if(arr.length > 30){
            this.passwordError = true
          }else {
            if(newValue === this.phone){
              this.passwordError = true
            }else{
              this.passwordError = false
            }
          }
        }
      },
    },
    rePassword:{
      handler: function(newValue){
        if( newValue == ''){
          this.rePasswordError = true
        }else{
          let arr = newValue.split('')
          if(arr.length < 6){
            this.rePasswordError = true
          }else if(arr.length > 30){
            this.rePasswordError = true
          }else {
            if(newValue === this.phone){
              this.rePasswordError = true
            }else{
              this.rePasswordError = false
            }
          }

          if(newValue !== this.password){
            this.sameError = true
          }else {
            this.sameError = false
          }
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .title{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .phoneNumber{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

  }

  .hints{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  .form{
    width: 90%;

    .input-block{
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 10px 0px;
      .label{
        width: 30%;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;
        font-size: 16px;
      }
      input{
        width: 70%;
        border: 1px solid #c1c1c1;
        height: 40px;
        border-radius: 8px;
        z-index: 10;
        text-align: right;
        padding-right: 8px;
      }
    }
  }



  input:focus{
    outline: none;
  }

  input::placeholder {
    color: #c1c1c1;
    font-weight: bold;
  }

  p{
    font-size: 8px;
    margin-top: 8px;
  }
}

.errorMsg{
  color: red;
  margin-bottom: 12px;
  width: 90%;
  text-align: right;
  position: relative;
  bottom: 8px;
}
</style>