<template>
  <div style="position:relative;">
    <bgLayout>
      <titleBar titleName="變更密碼"></titleBar>
      <contentBlock>
        <div class="content">
          <div class="title">
          </div>
          <div class="form">
            <div class="input-block">
              <div class="label">輸入舊密碼</div>
              <input type="password" v-model="oldPassword">
            </div>
            <p class="errorMsg" v-if="oldPasswordError">請輸入舊密碼</p>
            <div class="input-block">
              <div class="label">設定新密碼</div>
              <input type="password" v-model="password">
            </div>
            <p class="errorMsg" v-if="passwordError">限6-30字元，不能與手機號碼相同</p>
            <div class="input-block">
              <div class="label">確認新密碼</div>
              <input type="password" v-model="rePassword">
            </div>
            <p class="errorMsg" v-if="rePasswordError">限6-30字元，不能與手機號碼相同</p>
            <p class="errorMsg"  v-if="sameError">確認密碼不同</p>
          </div>
          <mainButton style="margin-top: 30px;z-index: 10" action-name="變更密碼" @action="change"></mainButton>

          <subButton style="margin-top: 20px;" action-name="取消" @action="cancel"></subButton>
        </div>
      </contentBlock>
    </bgLayout>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import bgLayout from "../../components/bgLayout";
import contentBlock from '../../components/contentBlock'
import mainButton from "../../components/mainButton";
import subButton from "../../components/subButton";
import loading from "../../components/loading";

export default {
  components:{
    titleBar,
    bgLayout,
    contentBlock,
    mainButton,
    subButton,
    loading
  },
  data(){
    return{
      isLoading: false,
      oldPassword: '',
      password: '',
      rePassword: '',
      oldPasswordError: false,
      passwordError: false,
      rePasswordError: false,
      sameError: false
    }
  },
  methods:{
    change(){
      //check
      this.oldPasswordCheck()
      this.passwordCheck()
      this.rePasswordCheck()

      if(
          this.oldPasswordError === false &&
          this.passwordError === false &&
          this.rePasswordError === false &&
          this.sameError === false
      ){
        //do some password change
        this.isLoading = true
        let url = process.env.VUE_APP_APIUrl + 'changePassword'
        let data = {
          "oldPassword": this.oldPassword,
          "newPassword": this.password,
        }
        this.axios.post(url, data, {
          headers:{
            Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
          }
        }).then( res =>{
          console.log(res)
          this.$swal({
            text: res.data.data,
          })
          // alert(res.data.data)
          this.isLoading = false
          this.$router.push({path: '/member/memberData'})
        })
        .catch(e =>{
          console.log(e)
          // alert(e.response.data.msg)
          this.$swal({
            text: e.response.data.msg,
          })
          this.isLoading = false
        })
      }
    },

    cancel(){
      this.$router.push({path: '/member/memberData'})
    },
    oldPasswordCheck(){
      if( this.oldPassword === ''){
        this.oldPasswordError = true
      }else{
        this.oldPasswordError = false
      }
    },
    passwordCheck(){
      if( this.password === ''){
        this.passwordError = true
      }else{
        this.passwordError = false
      }
    },
    rePasswordCheck(){
      if( this.rePassword === ''){
        this.rePasswordError = true
      }else{
        this.rePasswordError = false
      }
    },
  },
  watch:{
    oldPassword:{
      handler: function(newValue){
        if( newValue == ''){
          this.oldPasswordError = true
        }else{
          this.oldPasswordError = false
        }
      },
    },
    password:{
      handler: function(newValue){
        if( newValue == ''){
          this.passwordError = true
        }else{
          let arr = newValue.split('')
          if(arr.length < 6){
            this.passwordError = true
          }else if(arr.length > 30){
            this.passwordError = true
          }else {
            if(newValue === this.phone){
              this.passwordError = true
            }else{
              this.passwordError = false
            }
          }
        }
      },
    },
    rePassword:{
      handler: function(newValue){
        if( newValue == ''){
          this.rePasswordError = true
        }else{
          let arr = newValue.split('')
          if(arr.length < 6){
            this.rePasswordError = true
          }else if(arr.length > 30){
            this.rePasswordError = true
          }else {
            if(newValue === this.phone){
              this.rePasswordError = true
            }else{
              this.rePasswordError = false
            }
          }

          if(newValue !== this.password){
            this.sameError = true
          }else {
            this.sameError = false
          }
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .title{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .phoneNumber{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

  }

  .hints{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  .form{
    width: 90%;

    .input-block{
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px 0px;
      .label{
        width: 30%;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;
        font-size: 16px;
      }
      input{
        width: 70%;
        border: 1px solid #c1c1c1;
        height: 40px;
        border-radius: 8px;
        z-index: 10;
        text-align: right;
        padding-right: 8px;
      }
    }
  }



  input:focus{
    outline: none;
  }

  input::placeholder {
    color: #c1c1c1;
    font-weight: bold;
  }

  p{
    font-size: 8px;
    margin-top: 8px;
  }
}
.errorMsg{
  color: red;
  margin-bottom: 12px;
  width: 100%;
  text-align: right;
  position: relative;
  bottom: 8px;
}
</style>