<template>
  <div class="bg">
    <div class="logoLine">
      <img src="../assets/img/logo1.png" alt="" class="logo">
      <img src="../assets/img/logo2.png" alt="" class="logo">
    </div>
    <div class="d-flex jcc">
      <div class="content-block">
        <div class="content-title">
          您將透過此票卷兌換
        </div>
        <div class="content">
          <div class="content-subtitle-item">
            {{ name }}
          </div>
          <div class="content-subtitle-points">
            {{ points }}
          </div>
          <div class="divider-block">
            <img class="divider" src="../assets/img/dividing-line.png" alt="">
          </div>
          <div class="desc">
            <div class="desc_title">
              <span>●</span> 紅利點數怎麼用？
            </div>
            <div class="desc_answer_title">
              <div class="mark"></div>
              保養維修可使用
            </div>
            <div class="desc_answer_desc">
              紅利點數可折抵全台中華三菱 <br>
              服務廠維修、保養的消費金額
            </div>
            <div class="desc_answer_title">
              <div class="mark"></div>
              紅利抵現金
            </div>
            <div class="desc_answer_desc">
              紅利點數100點折抵NT$ 1 元 <br>
              <span>(折抵金額最多不得超過發票上零件消費總金額的50%)</span>
            </div>
          </div>
        </div>
        <div class="submitBtn" @click="excange">
          <img src="../assets/img/points-btn.png" alt="">
        </div>
      </div>
    </div>
    <!-- loading ▼ -->
    <div class="loading" v-if="loading">
      <div class="loader">
        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
        <path opacity="0.2" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
          <path fill="#fff" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
          C22.32,8.481,24.301,9.057,26.013,10.047z">
          <animateTransform attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 20 20"
                            to="360 20 20"
                            dur="0.5s"
                            repeatCount="indefinite"/>
          </path>
        </svg>
      </div>
      載入中，請稍候...
    </div>
    <!-- loading ▲ -->
  </div>
</template>
<script>
import liff from "@line/liff";
export default {
  data(){
    return{
      couponID: this.$route.query.couponID,
      bounsID: this.$route.query.bounsID,
      name: '',
      points: '',
      loading:false
    }
  },
  created() {
    this.lineInit()
    // this.fetchData() //test
  },
  methods:{
    async lineInit() {
      await liff
          .init({
            liffId: process.env.VUE_APP_LIFFID // project liff id
          })
          .then(() => {
            if (!liff.isLoggedIn()) {
              liff.login({ redirectUri: window.location.href });
            } else {
              liff.getFriendship().then(data => {
                if (data.friendFlag) {
                  liff.getProfile()
                      .then(res => {
                        window.localStorage.setItem("userToken", res.userId);
                      })
                  this.getAccessTokenAndFetchData()
                } else {
                  this.linkToAddFriend()
                }
              });
            }
          })
          .catch(err => {
            console.log(err.code, err.message);
          });
    },
    getAccessTokenAndFetchData(){
      this.loading = true
      const accessToken = liff.getAccessToken();
      window.localStorage.setItem('AccessToken', accessToken)
      this.fetchData()
    },
    linkToAddFriend(){
      this.$store.commit('updateLoadingStatus', false)
      window.location.replace(process.env.VUE_APP_ADDFRIEND);//加好友連結
    },
    excange(){
      this.$swal({
        text: "確認兌換?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then((res) =>{
        if(res.isConfirmed){
          this.loading = true
          let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' +'saveBonusTypeT'
          //let url = process.env.VUE_APP_APIUrl + 'saveBonusTypeT'
          let data = {
            bonusID: this.bounsID,
            coupon_code: this.couponID,
            line_uid: window.localStorage.getItem('userToken')
          }
          this.axios.post(url, data).then( res =>{
            console.log(res.data.data)
            this.$swal({
              text: '點數兌換成功!',
              type: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "確定",
            }).then(()=>{
              this.loading = false
              liff.closeWindow()
            })
          }).catch(e =>{
            console.log(e.response.data)
            this.$swal({
              text: e.response.data.msg,
              type: "error",
              showCancelButton: false,
              confirmButtonColor: "rgb(184,11,5)",
              confirmButtonText: "確定",
            }).then(()=>{
              if(!e.response.data.data.IsBind){
                this.$router.push({path: '/member/memberData'})
              }
              this.loading = false
              liff.closeWindow()
            })
          })
        }
      })

    },
    fetchData(){
      let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'getBonusInfo'
      //let url = process.env.VUE_APP_APIUrl + 'getBonusInfo'
      let data = {
        bonusID : this.bounsID
      }
      this.axios.post(url, data).then( res =>{
        console.log(res.data.data)
        let data = res.data.data
        this.name = data.name
        this.points = `點數${data.giftamount}點`
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
body{
  background: rgb(80,11,5);
}
.bg{
  position: relative;
  left: -1px;
  width: 101%;
  min-height: 106vh;
  background-image: url("~@/assets/img/points-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  .logoLine{
    width: 95%;
    display: inline-flex;
    justify-content: space-between;
    margin: 8px 8px;
    .logo{
      width: 32px;
      z-index: 2;
    }
  }
  .d-flex{
    display: flex;
  }
  .jcc{
    justify-content: center;
  }
  .content-block{
    width: 350px;
    height: 400px;
    position: absolute;
    top: 43%;
    margin: 0 auto;
    border-top: none;
    border-right: 5px solid red;
    border-bottom: 5px solid red;
    border-left: 5px solid red;
    border-radius: 8px;
    z-index: 3;
    background: #fff;

    .content-title{
      background: red;
      color: #fff;
      width: 100%;
      text-align: center;
      text-shadow: 1px 1px 1px black;
      font-size: 24px;
      font-weight: bold;
      padding: 4px 0px;
    }
    .content{
      overflow: auto;
      .content-subtitle-item{
        margin-top: 12px;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        height: 40px;
      }
      .content-subtitle-points{
        margin-bottom: 12px;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        height: 40px;
      }
      .divider-block{
        display: flex;
        justify-content: center;
        .divider{
          width: 95%;
        }
      }
      .desc{
        width: 100%;
        height: 240px;
        .desc_title{
          font-size: 18px;
          font-weight: bold;
          color: #424247;
          margin-top: 16px;
          margin-left: 16px;
        }

        .desc_answer_title{
          display: inline-flex;
          color: rgb(184,11,5);
          font-size: 18px;
          font-weight: bold;
          margin-left: 32px;
          margin-top: 12px;

          .mark{
            width: 7px;
            height: 18px;
            border-radius: 10px;
            background: rgb(184,11,5);
            margin-right: 5px;
            position: relative;
            top: 4px;
          }
        }

        .desc_answer_desc{
          margin-left: 44px;
          font-size: 16px;
          span{
            position: relative;
            top: -8px;
            font-size: 12px;
          }
        }
      }
    }
    .submitBtn{
      position: absolute;
      bottom: -7%;
      display: flex;
      justify-content: center;
      z-index: 4;
      width: 100%;
      img {
        width: 220px;
      }
    }
  }
}

.loading {
  background: rgba(103, 12, 14, .7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading svg {
  width: 80px;
  height: 80px;
}
</style>
