<template>
  <!-- loading ▼ -->
  <div class="loading">
    <div class="loader">
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        enable-background="new 0 0 40 40"
        xml:space="preserve"
      >
        <path
          opacity="0.2"
          fill="#fff"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path
          fill="#fff"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
          C22.32,8.481,24.301,9.057,26.013,10.047z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.5s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    載入中，請稍候...
  </div>
  <!-- loading ▲ -->
</template>
<script>
import liff from '@line/liff'
export default {
  data() {
    return {}
  },
  created() {
    this.lineInit()
  },
  methods: {
    async lineInit() {
      //liff Init
      this.$store.commit('updateLoadingStatus', true)
      await liff
        .init({
          liffId: process.env.VUE_APP_LIFFID // project liff id
        })
        .then(() => {
          //detect login or not
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: window.location.href })
          } else {
            liff.getFriendship().then((data) => {
              if (data.friendFlag) {
                this.liffGetProfile()
              } else {
                this.linkToAddFriend()
              }
            })
          }
        })
        .catch((err) => {
          console.log(err.code, err.message)
        })
    },
    liffGetProfile() {
      liff
        .getProfile()
        .then((res) => {
          window.localStorage.setItem('userToken', res.userId)
          window.localStorage.setItem('userName', res.displayName)
          this.liffGetAccessToken()
        })
        .catch((e) => {
          this.$swal({
            text: 'Line Liff錯誤'
          })
          liff.closeWindow()
          console.log(e)
        })
    },
    liffGetAccessToken() {
      const accessToken = liff.getAccessToken()
      window.localStorage.setItem('AccessToken', accessToken)
      if (this.$route.query.to === 'testRide') {
        this.$router.push({
          path: 'testRide',
          query: {
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            utm_term: this.$route.query.utm_term,
            utm_content: this.$route.query.utm_content,
            shortname: this.$route.query.shortname
          }
        })
      }
      if (this.$route.query.to === 'carOwnerBinding') {
        this.$router.push({ path: '/member/phoneCheck' })
      }
      if (this.$route.query.to === 'ownerData') {
        this.$router.push({ path: '/member/memberData' })
      }
      if (this.$route.query.to === 'schedule') {
        this.$router.push({
          path: '/member/scheduleMaintenance'
        })
      }
      if (this.$route.query.to === 'survey1') {
        this.$router.push({
          path: '/member/survey1',
          query: {
            dealer: this.$route.query.dealer,
            ministry: this.$route.query.ministry
          }
        })
      }
      if (this.$route.query.to === 'survey2') {
        this.$router.push({ path: '/member/survey2' })
      }
      if (this.$route.query.to === 'survey3') {
        this.$router.push({ path: '/member/survey3' })
      }
      if (this.$route.query.to === 'salesBinding') {
        this.$router.push({ path: '/sales/binding' })
      }
    },
    linkToAddFriend() {
      this.$store.commit('updateLoadingStatus', false)
      window.location.replace(process.env.VUE_APP_ADDFRIEND) //加好友連結
    }
  }
}
</script>

<style>
/* loading css */
.loading {
  background: rgba(103, 12, 14, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading svg {
  width: 80px;
  height: 80px;
}
</style>
