<template>
  <div class="btn-block" @click="$emit('action')">
    {{ actionName }}
  </div>
</template>

<script>
export default {
  props:[
    'actionName'
  ]
}
</script>

<style lang="scss" scoped>
.btn-block{
  z-index: 10;
  background: white;
  color: #C40017;
  width: 45%;
  height: 45px;
  border: 1px solid #C40017;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>