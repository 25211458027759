<template>
  <div style="position: relative;" data-app>
    <h1>預約試乘</h1>
    <div class="line"></div>
    <h2>預約試乘/預約賞車/購車諮詢</h2>
    <div class="pick-car-block">
      <div class="pick-car">
        選擇車款
      </div>
    </div>
    <div class="swiper_block">
      <div class="loading-block"  v-if="!isDataLoading">
        <img src="../assets/img/loading.svg" alt="">
      </div>
      <swiper class="swiper" :options="swiperOption" v-if="isDataLoading">
        <swiper-slide v-for="item in carList" :key="item.name" >
          <div :class="{'active' : selectedCar === item.name, 'none-active': selectedCar !== item.name}" style="z-index: 10;" @click="selectCar(item.name)">
            <img :src="item.pic_url"  alt="">
            <p>{{item.name}}</p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" v-if="carList.length > 1"></div>
      <div class="swiper-button-next" v-if="carList.length > 1"></div>
    </div>
    <div class="form-block">
      <div class="container">
        <div style="position: relative;">
          <label for="name"><span style="color: red">*</span>姓名</label><br>
          <input type="text" id="name" v-model="name" style="padding-right: 40px">
          <p class="errorMsg" v-if="nameEmpty">請輸入姓名</p>
          <img style="width: 40px;height: 40px;position: absolute;top: 35px;right: 5px;" src="../assets/img/person.svg" alt="">
        </div>
        <div class="gender-block">
          <input type="radio" id="male" name="gender" value="M" v-model="gender">
          <label for="male" style="font-weight: normal;font-size: 14px;margin-left: 4px;">先生</label><br>
          <input type="radio" id="female" name="gender" value="F" v-model="gender" style="margin-left: 8px;">
          <label for="female" style="font-weight: normal;font-size: 14px;margin-left: 4px;">小姐</label>
        </div>
        <p class="errorMsg" v-if="genderEmpty">請選擇性別</p>
        <br>
        <div style="position: relative;">
          <label for="phone"><span style="color: red">*</span>手機 <span style="font-size: 8px">例：0912345678</span></label><br>
          <input type="phone" v-model="phone" id="phone">
          <p class="errorMsg" v-if="phoneEmpty">請輸入手機</p>
          <p class="errorMsg" v-if="phoneType">請確認手機格式</p>
          <img style="width: 40px;height: 40px;position: absolute;top: 35px;right: 5px;" src="../assets/img/phone.svg" alt="">
        </div>
        <div style="height: 20px; width: 100%;"></div>
        <label for="name">選擇展示中心</label><br>
        <div class="loading-block"  v-if="selectCarLoading">
          <img src="../assets/img/loading1.svg" alt="">
        </div>
        <div class="citySelector" v-if="selectedCarAlready === true" data-app>
          <v-select
              :items="citySelectList"
              label="請選擇縣市"
              solo
              v-model="city"
              style="width: 45%;"
          ></v-select>
          <v-select
              :items="areaSelectList"
              label="請選擇區域"
              solo
              v-model="area"
              style="width: 45%;margin-left: 8px;"
          ></v-select>
          <br>
          <div class="loading-block"  v-if="showStoreLoading">
            <img src="../assets/img/loading1.svg" alt="">
          </div>
          <div v-if="showNoResult">
            選取範圍內無營業所
          </div>
          <v-select
              v-if="showStore"
              :items="storeSelectList"
              label="請選擇展示中心"
              solo
              v-model="store"
              style="width: 100%;"
          ></v-select>
        </div>
        <div class="detailBlock" v-if="this.store !== ''">
          <p class="title">
            {{ selectedStore.itemName }}
          </p>
          <p class="address">
            {{ selectedStore.address }}
          </p>
          <p class="phone">
            {{ selectedStore.tel }}
          </p>
          <p class="workTime">
            {{ selectedStore.business_time }}
          </p>
        </div>
        <p class="errorMsg" v-if="storeEmpty">請選擇展示中心</p>
        <div v-if="selectedCar === ''">
          請點選試乘車輛
        </div>
        <br>
        <label>請問這台車是要買給誰使用</label><br>
        <div class="reason-block" style="margin-top: 16px;">
          <v-select
              :items="reasonList"
              label="買給誰使用"
              solo
              v-model="reason"
              style="width: 100%;"
          ></v-select>
        </div>
        <label>請問這台車是</label><br>
        <div class="purchases-block" style="margin-top: 16px;">
          <v-select
              :items="purchasesList"
              label="首購/換購/增購"
              solo
              v-model="purchases_no"
              style="width: 100%;"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="agree-block">
      <input type="checkbox" id="agreeInfo" v-model="agreeInfo"> <label for="agreeInfo">我已閱讀並 </label><span @click="agreeModal = true">同意個資授權</span>
    </div>
    <p class="errorMsg" style="width: 90%;margin: 0 auto;" v-if="agreeError">請同意個資授權</p>
    <div class="agreeModal" v-if="false" @click.prevent="agreeModal = false">
      <div class="block"></div>
    </div>
    <div class="submit-btn-block">
      <div class="submit-btn" @click="submit" ><span v-if="!submitLoading">送出資料</span> <img v-if="submitLoading" src="../assets/img/loading1.svg" alt=""> </div>
    </div>
    <v-dialog
        v-model="agreeModal"
    >
        <div class="modal-block">
          <h3>個資授權同意</h3>
          <div class="content-block">
            <p class="title">
              個人資料的蒐集、處理、利用
            </p>
            <p class="content-text">
              中華汽車網站所取得的個人資料，都僅供網站原來所說明的使用目的和範圍，除非事先說明、或依照相關法律規定，否則中華汽車不會將資料提供給第三人、或移作其他目的使用。
            </p>
            <p class="sub-title">
              <span style="color: #D9363E ; margin-right: 4px;">█</span>蒐集之目的：
            </p>
            <p class="content-text ml18">
              蒐集之目的在於預約賞車試乘或參與中華汽車所主辦之相關活動。
            </p>
            <p class="sub-title">
              <span style="color: #D9363E ; margin-right: 4px;">█</span>蒐集之個人資料類別：
            </p>
            <p class="content-text ml18">
              中華汽車於網站內蒐集的個人資料包括為消費者之姓名、電話等資訊。
            </p>
            <p class="sub-title ">
              <span style="color: #D9363E ; margin-right: 4px;">█</span>利用期間、地區、對象及方式：
            </p>
            <p class="content-text ml18">
              1. 期間：當事人要求停止使用或中華汽車停止提供服務之日為止。<br>
              2. 地區：所提供之個人資料將用於台灣地區。<br>
              3. 利用對象及方式：對於您於本網站所提供之個人資料，您同意<br>
            </p>
            <p class="content-text ml18" style="margin-left: 12px;">
              (1) 預約賞車試乘：個人資料將提供予中華汽車包括您選擇試乘之經銷營業據點所屬經銷商，基於提供試乘服務之目的，進行蒐集、處理、保存、傳遞及使用該等資料。
            </p>
            <p class="content-text ml18" style="margin-left: 12px;">
              (2) 活動參與：個人資料將提供中華汽車及其委託之活動公司，基於活動之進行目的，進行蒐集、處理、保存、傳遞及使用該等資料。
            </p>
            <p class="sub-title">
              <span style="color: #D9363E ; margin-right: 4px;">█</span>會員就個人資料之權利：
            </p>
            <p class="content-text ml18">
              中華汽車所蒐集個人資料之當事人，依個人資料保護法得對中華 汽車行使以下權利：<br>
              1.查詢或請求閱覽。<br>
              2.請求製給複製本。<br>
              3.請求補充或更正。<br>
              4.請求停止蒐集、處理或利用。<br>
              5.請求刪除。<br>
              如欲行使上述權利，可與中華汽車客服連絡進行申請。<br>
            </p>

            <p class="content-text ml18" style="font-weight: bold;position: relative;">
              請注意！您得自由選擇是否提供個人資料，如拒絕提供服務與活動參與所需必要之資料， 將可能導致無法享受完整服務或完全無法使用該項服務 <span style="color: #D9363E; position: absolute; top: 0; left: -18px;">█</span>
            </p>
          </div>
        </div>
    </v-dialog>
  </div>
</template>

<script>
import cities from '../assets/json/twCities.json'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import liff from "@line/liff";
export default {
  name: 'Home',
  title: 'Pagination',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isDataLoading: false,
      agreeModal: false,
      agreeInfo: false,
      agreeError: false,
      showStore: false,
      showStoreLoading: false,
      showNoResult: false,
      nameEmpty: false,
      phoneEmpty: false,
      genderEmpty: false,
      storeEmpty: false,
      phoneType: false,
      submitLoading: false,
      selectedCarAlready: false,
      selectCarLoading: false,
      locationData: [],
      citySelectList: [],
      areaSelectList:[],
      citySelect: [],
      storeSelectList:[],
      reasonList:['自己使用', '給小孩使用', '給伴侶使用', '其他'],
      purchasesList:['初購(第一次購車)', '增購(已有不止一台車)', '換購'],
      carList: [],
      storeList: [],
      compareList: [],
      selectedStore: {},
      cities: cities,
      error: [false],
      selectedCar: '',
      city: '',
      area: '',
      store:'',
      reason:'',
      purchases_no:'',
      name: '',
      phone: '',
      gender: '',
      srCd: '',
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        // loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }
  },
  created() {
    this.getCarStoreData()
  },
  mounted() {
    // this.cities.forEach(item =>{
    //   this.citySelectList.push(item.name)
    // })
  },
  methods:{
    submit(){
      //check
      this.checkName()
      this.checkPhone()
      this.checkGender()
      this.checkStore()
      if( this.agreeInfo === false){
        this.agreeError = true
      }else{
        this.agreeError = false
      }
      //submit
      if(
          this.nameEmpty === false &&
          this.phoneEmpty === false &&
          this.genderEmpty=== false &&
          this.phoneType === false &&
          this.agreeError === false &&
          this.city !== '' &&
          this.area !== '' &&
          this.store !== ''){
        this.saveTestDriveData()
      }

    },
    checkStore(){
      if(this.store === ''){
        this.storeEmpty = true
      } else {
        this.storeEmpty = false
      }
    },
    checkName(){
      if(this.name === ''){
        this.nameEmpty = true
      }else{
        this.nameEmpty = false
      }
    },
    checkPhone(){
      if(this.phone === ''){
        this.phoneEmpty = true
      }else{
        this.phoneEmpty = false
      }
    },
    checkGender(){
      if(this.gender === ''){
        this.genderEmpty = true
      }else{
        this.genderEmpty = false
      }
    },
    async getCarStoreData(){
      let url = process.env.VUE_APP_APIUrl + 'getTestDriveCarList'
      await this.axios.post(url, {}, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        this.carList = res.data.data
        if(this.$route.query.shortname){
          this.carList.forEach((i) =>{
            if(i.shortname === this.$route.query.shortname){
              this.swiperOption.centeredSlides = true
              this.selectCar(i.name)
              this.carList = [i]
            }
          })
        }
        this.isDataLoading = true
      })
    },
    async getTestDriveNodeList(){
      this.showStoreLoading = true
      let url = process.env.VUE_APP_APIUrl + 'getTestDriveNodeList'
      let data = {
        "location": this.city,
        "town": this.area,
        "carName": this.selectedCar
      }
      await this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        if(res.data.data){
          this.storeList = res.data.data
          console.log(this.storeList)
          this.storeSelectList = []
          this.compareList = []
          res.data.data.forEach(item =>{
            let itemName = item.dealername + ' ' + item.fullname
            this.storeSelectList.push( itemName )
            this.compareList.push({
              itemName: itemName,
              fullname: item.fullname,
              srCd: item.sr_cd,
              tel: item.tel,
              address: item.address,
              business_time: item.business_time
            })
          })
          this.showStore= true

        } else{
          this.storeSelectList = []
          this.showNoResult = true
        }

        this.showStoreLoading = false
      })
      .catch(e =>{
        console.error(e)
        this.showStoreLoading = false
      })
    },
    async saveTestDriveData (){
      this.submitLoading = true
      await this.compareList.forEach(item =>{
        if(item.itemName === this.store){
          console.log(item)
          this.srCd = item.srCd
        }
      })
      let url = process.env.VUE_APP_APIUrl + 'saveTestDriveData'
      let data = {
        "custName": this.name,
        "mobile": this.phone,
        "gender": this.gender,
        "srCd": this.srCd,
        "carType": this.selectedCar,
        "utm_source": this.$route.query.utm_source,
        "utm_medium": this.$route.query.utm_medium,
        "utm_campaign": this.$route.query.utm_campaign,
        "utm_term": this.$route.query.utm_term,
        "utm_content": this.$route.query.utm_content,
        "reason": this.reason,
        "purchases_no": this.purchases_no,
      }
      await this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        if(res.data.msg === 'success'){
          this.submitLoading = false
          this.$swal({
            text: res.data.data,
          })
          // alert(res.data.data)
          //close liff
          liff.closeWindow()
        }
      })
      .catch(e =>{
        this.submitLoading = false
        this.$swal({
          text: e.response.data.msg,
        })
        // alert(e.response.data.msg)
        //close liff
        liff.closeWindow()
      })
    },
    selectCar(carName){
      this.selectCarLoading = true
      this.selectedCar = carName
      let url = process.env.VUE_APP_APIUrl + 'getLocationList'
      let data = {
        "carName": this.selectedCar
      }
      this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      })
      .then(res =>{
        console.log(res.data.data)
        this.locationData = res.data.data
        this.locationData.forEach(item =>{
          this.citySelectList.push(item.location)
        })
        this.city=''
        this.area=''
        this.store=''
        this.selectCarLoading = false
        this.selectedCarAlready = true
      })
      .catch(e =>{
        console.log(e.response.data)
      })
    }
  },
  watch : {
    city:{
      handler: function(newValue){
        this.locationData.forEach(item =>{
          if(item.location === newValue){
            this.areaSelectList = []
            item.town.forEach(i =>{
              this.areaSelectList.push(i.name)
            })
          }
        })
        this.area = ''
        this.showStore= false
      },
    },
    area:{
      handler: function(newValue){
        if( newValue !== ''){
          this.showNoResult = false
          console.log('pick')
          this.getTestDriveNodeList()
        }
      },
    },
    name:{
      handler: function(newValue){
        if( newValue == ''){
          this.nameEmpty = true
        }else{
          this.nameEmpty = false
        }
      },
    },
    phone:{
      handler: function(newValue){
        if( newValue == ''){
          this.phoneType = false
          this.phoneEmpty = true
        }else{
          this.phoneEmpty = false
          let regex = /^09[0-9]{8}$/;
          if (!regex.test(this.phone)){
            this.phoneType = true
          }else{
            this.phoneType = false
          }
        }
      },
    },
    agreeInfo:{
      handler: function(newValue){
        if( newValue === false){
          this.agreeError = true
        }else{
          this.agreeError = false
        }
      },
    },
    gender:{
      handler: function(newValue){
        if( newValue == ''){
          this.genderEmpty = true
        }else{
          this.genderEmpty = false
        }
      },
    },
    store:{
      handler: function(newValue){
        if( newValue !== ''){
          this.storeEmpty = false
          this.compareList.forEach(item =>{
            if(item.itemName === newValue){
              this.selectedStore = item
            }
          })
        }
      },
    },
  }
}
</script>
<style lang="scss" scoped>
h1{
  color: #0175AB;
  margin: 0px auto 10px;
  text-align: center;
  padding-top: 10px;
}

h2{
  margin: 5px 0px 0px 0px;
  color: #5A5656;
  text-align: center;
  font-size: 1.2em;
}

.line{
  width: 90%;
  margin: 0 auto;
  height: 5px;
  background:linear-gradient( 90deg,
      #ffffff 0%,
      #ebeced 5%,
      #bebfc2 30%,
      #bebfc2 70%,
      #ebeced 95%,
      #ffffff 100%
  );
}
.pick-car-block{
  width: 90%;
  margin: 0 auto;
  .pick-car{
    background: #D3D3D4;
    color: #D9363E;
    width: 90px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
  }
}



.swiper {
  margin-top: 10px;
  height: 120px;
  width: 90%;
  border: 1px transparent solid;

  .swiper-slide {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    img{
      width: 100%;
    }
  }
}

.swiper_block{
  position: relative;

  .loading-block{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-prev{
    width: 0px ;
    color: #5A5656;
    top: 35px

  }
  .swiper-button-next {
    width: 0px ;
    color: #5A5656;
    top: 35px

  }

  .active{
    border: 1px solid #D9363E;
    padding: 1px;

    img {
      width: 96%;
    }
  }

  .none-active{
    padding: 1px;

    img {
      width: 96%;
    }
  }
}



.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 20px;
}

.form-block{
  width: 90%;
  margin: 10px auto;
  background: #C2D2E5;
  border-radius: 20px;
  .container{
    width: 90%;
    padding: 15px;
    margin: 0 auto;

    .gender-block{
      display: inline-flex;
      align-items: center;
      height: 19px;
      margin: 8px 0px 20px 0px;
    }
  }
  label{
    color: black;
    font-weight: bold;
  }
  input[type='text']{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border: 2px #c1c1c1 solid;
    border-radius: 10px;
    background: #fff;
    padding-left: 12px;
    font-size: 16px;
  }

  input[type='phone']{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border: 2px #c1c1c1 solid;
    border-radius: 10px;
    background: #fff;
    padding-left: 12px;
    font-size: 16px;
  }
}

.citySelector{
  margin-top: 12px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap
}

textarea:focus, input:focus{
  outline: none;
}

.agree-block{
  width: 90%;
  margin: 12px auto;
  display: flex;
  align-items: center;
  label{
    margin-left: 8px;
    color: #5A5656;
    font-size: 14px;
    font-width: 600;
  }
  span{
    text-decoration:underline;
    cursor: pointer;
    font-size: 14px;
    color: #5A5656;
    margin-left: 8px;
  }
}

.modal-block{
  background: #C2D2E5;
  margin: 0;
  padding: 20px;

  h3{
    padding-top: 8px;
    text-align: center;
    color: #0175AB;
    font-width: bold;
  }

  .content-block{
    margin-top: 10px;
    padding-top: 8px;
    border-top: 0.5px solid black;

    .title{
      font-weight: bold;
      color: #D9363E;
      font-size: 16px;
    }

    .sub-title{
      font-weight: bold;
      color: #D9363E;
      font-size: 14px;
    }

    .content-text{
      margin: 4px 0px;
      font-size: 8px;
    }
  }
}

.submit-btn-block{
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .submit-btn{
    height: 35px;
    width: 120px;
    background: #0175AB;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 8px 24px;
  }
}

.ml18{
  margin-left: 18px !important;
}

.errorMsg{
  color: red
}

.detailBlock{
  width: 100%;
  margin: 4px auto;
  background: #fff;
  min-height: 60px;
  padding: 8px;
  box-shadow: 1px 1px 3px gray;

  .title{
    font-weight: bold;
    font-size: 16px !important;
    color: #0175AB;
  }

  p{
    margin: 4px 0px;
    font-size: 14px;
  }
}
</style>
