<template>
  <div style="position: relative">
    <div class="bg">
      <titleBar :titleName="pageName"></titleBar>
      <div class="X" v-if="openModal" @click="closeModal">X</div>
      <div class="content-block" v-if="!openModal">
        <div class="content"  style="padding-bottom: 24px">
          <div class="controller">
            <div class="no">1</div>
            <div class="sub-title">
              請問您的姓名
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="text" v-model="name">
            </div>
            <p class="errorMsg" v-if="nameEmpty">請輸入姓名</p>
            <p class="errorMsg" v-if="nameType">請填寫「中文姓名」全名（最多五個字）</p>
          </div>
          <div class="controller">
            <div class="no">2</div>
            <div class="sub-title">
              請問您的手機號碼
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="text" v-model="phone">
            </div>
            <div class="errorMsg" v-if="phoneError">
              請確認手機格式
            </div>
          </div>
          <div class="controller">
            <div class="no">3</div>
            <div class="sub-title">
              請問您的性別
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="radio" id="male" name="gender" value="男" v-model="gender">
              <label for="male">男</label>
              <input type="radio" id="female" name="gender" value="女" v-model="gender">
              <label for="female">女</label>
            </div>
          </div>
          <div class="controller">
            <div class="no">4</div>
            <div class="sub-title">
              請問您的婚姻狀況
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <select name="marriage" v-model="marriage" >
                <option :value="null" selected disabled>請選擇您的婚姻狀況</option>
                <option :value="i" v-for="(i , ind) in marriageOption" :key="ind+'asd'">{{ i }}</option>
              </select>
            </div>
          </div>
          <div class="controller">
            <div class="no">5</div>
            <div class="sub-title">
              請問您的生日
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="date" v-model="birthday" max="2002-01-01">
            </div>
          </div>
          <div class="controller">
            <div class="no">6</div>
            <div class="sub-title">
              展示中心
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <select name="area" id="" v-model="store" v-if="!storeDataLodging">
                <option :value="null" selected disabled>請選擇服務中心/展示中心</option>
                <option :value="i.sr_cd" v-for="(i, ind) in storeOption" :key="ind + 'dsa'">{{ i.dealername + ' ' + i.fullname }}</option>
              </select>
              <img v-if="storeDataLodging" src="../../assets/img/loading1.svg" alt="">
            </div>
          </div>
          <div class="controller">
            <div class="no">7</div>
            <div class="sub-title">
              銷售專員
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <select v-if="!salesDataLodging && store !== ''" name="sale" id="" v-model="sale">
                <option :value="null" selected disabled>請選擇服務專員</option>
                <option :value="i.id" v-for="(i, ind) in saleList" :key="ind + 'dsa'">{{ i.name }}</option>
              </select>
              <img v-if="salesDataLodging" src="../../assets/img/loading1.svg" alt="">
            </div>
          </div>
          <div class="controller">
            <div class="no">8</div>
            <div class="sub-title">
              您有興趣的車款<span style="font-size: 10px; padding-left: 4px">(可複選，最多不超過兩款)</span>
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <div class="option-block">
                <div class="half" v-for="(i, ind) in Q8Options" :key="ind + '123'">
                  <input type="checkbox" :id="'option' + ind" v-model="i.checked" >
                  <label :for="'option' + ind">{{ i.text }}</label>
                </div>
              </div>
            </div>
            <div class="errorMsg" v-if="more1Error">
              車款 最多選兩個
            </div>
          </div>
          <div class="controller">
            <div class="no">9</div>
            <div class="sub-title">
              請問您的休閒活動＆興趣<span style="font-size: 10px; padding-left: 4px">(可複選)</span>
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <div class="option-block">
                <div :class="{'half': true, 'w-full': ind === 0 || ind >= 9}" v-for="(i, ind) in Q9Options" :key="ind + '3'">
                  <input type="checkbox" :id="'2option' + ind" v-model="i.checked">
                  <label :for="'2option' + ind">{{ i.text }}</label>
                </div>
              </div>
            </div>
            <div class="errorMsg" v-if="moreError">
              休閒活動/興趣 最多選三個
            </div>
          </div>
          <div class="controller">
            <div class="no">10</div>
            <div class="sub-title">
              請問您是三菱車主嗎
            </div>
            <div class="form-controller">
              <div class="space"></div>
              <input type="radio" id="yes" name="isCarOwner" value="1" v-model="ownerbrand">
              <label for="yes">是</label>
              <input type="radio" id="no" style="margin-left: 8px;" name="isCarOwner" value="0" v-model="ownerbrand">
              <label for="no">否</label>
            </div>
          </div>
          <div class="controller">
            <div class="form-controller">
              <div class="space"></div>
              <input type="checkbox" id="agree" v-model="agree">
              <label for="agree" style="letter-spacing: 2px; z-index: 10">我同意上述 <span style="text-decoration: underline" @click="openPpModal">個資政策及其條款</span></label>
            </div>
          </div>
          <mainButton action-name="送出問卷" style="margin: 20px 0px; z-index: 10" @action="submitForm"></mainButton>
        </div>
      </div>
      <div class="content-block" v-if="openModal" style="min-height: 90vh !important;">
        <div  class="content" style="min-height: 45vh !important;">
          <p style="font-weight: bold;font-size: 16px;margin: 24px auto 16px auto; width: 90%;">依據個人資料保護法等相關規定，以下告知事項請 台端詳閱：</p>
          <p style="font-size: 14px;margin: 0px auto;width: 90%;">參加本活動前，請務必詳閱本活動辦法內容，當您透過本活動網站輸入個人資料參與活動時表示已同意下述蒐集個人資料告知事項與同意書之內容。</p>
          <p style="font-size: 14px;margin: 2px auto;width: 90%;">本人同意中華汽車工業股份有限公司(以下簡稱CMC)，依個人資料保護法規定，得為行銷及客戶服務管理之目的，於中華民國蒐集、處理及利用本人之上網登錄之個人資料，包含本人之姓名、聯絡電話等，並針對本人的需求提供個人化的行銷或服務內容。CMC將以包含但不限於簡訊、電話等聯絡方式，使本人知悉產品銷售、售後服務等權益事項、優惠、贈品或相關資訊及廣告。本人依照個人資料保護法得隨時請求CMC查閱、給予複本、或補正本人之個人資料，亦得隨時洽CMC之客服專線(0800--030-580) 拒絕CMC繼續蒐集、處理、利用或刪除本人之個人資料；本人同意由CMC依本同意書之約定持續蒐集、處理、利用本人所提供之個人資料，截至本人主動請求CMC刪除、停止處理或利用該個人資料之日或CMC執行業務所必需之保存期間止，CMC始終止其個人資料之蒐集、處理及利用。本人並同意CMC得將本人之個人資料提供予相對人之關係企業，於與前述目的類似之目的範圍內，蒐集、處理及利用。本人得自由選擇是否提供相關個人資料，惟若本人不提供時，本人瞭解將無法參加本活動。</p>
        </div>
      </div>

      <img src="../../assets/img/bg1.svg" alt="" class="bg-img-top-right">
      <img src="../../assets/img/bg2.svg" alt="" class="bg-img-bottom-left">
    </div>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import loading from '../../components/loading'
import titleBar from "../../components/titleBar";
import mainButton from "../../components/mainButton";
import liff from "@line/liff";

export default {
  components:{
    loading,
    titleBar,
    mainButton
  },
  data(){
    return {
      //system
      isLoading: false,
      isMaintenance: false,
      openModal :false,
      salesDataLodging: false,
      storeDataLodging: false,
      phoneError: false,
      moreError: false,
      more1Error: false,
      nameEmpty: false,
      nameType: false,
      pageName: '從菱開始 好友召集令 問卷',
      //data
      dealer: this.$route.query.dealer,
      ministry: this.$route.query.ministry,
      name: '',
      phone: '',
      gender: '',
      marriage: '',
      birthday: '2002-01-01',
      store: '',
      sale: '',
      ownerbrand: null,
      agree: false,
      marriageOption: ['單身', '已婚有小孩', '未婚有小孩', '已婚無小孩'],
      Q8Options:[
        {
          text: 'ZINGER Pick up',
          value: 'ZINGER Pick up',
          checked: false,
        },
        {
          text: 'ZINGER',
          value: 'ZINGER',
          checked: false,
        },
        {
          text: 'COLT PLUS',
          value: 'COLT PLUS',
          checked: false,
        },
        {
          text: 'GRAND LANCER',
          value: 'GRAND LANCER',
          checked: false,
        },
        {
          text: 'OUTLANDER',
          value: 'OUTLANDER',
          checked: false,
        },
        {
          text: 'ECLIPSE CROSS',
          value: 'ECLIPSE CROSS',
          checked: false,
        },
        {
          text: '菱利廂車',
          value: '菱利廂車',
          checked: false,
        },
        {
          text: '菱利貨車',
          value: '菱利貨車',
          checked: false,
        },
        {
          text: 'E-VERYCA',
          value: 'E-VERYCA',
          checked: false,
        },
        {
          text: '得利卡貨車',
          value: '得利卡貨車',
          checked: false,
        },
        {
          text: '得利卡廂車',
          value: '得利卡廂車',
          checked: false,
        },
      ],
      Q9Options:[
        {
          text: '逛街、購物(實體店面)',
          value: '逛街、購物(實體店面)',
          checked: false,
        },
        {
          text: '線上網購',
          value: '線上網購',
          checked: false,
        },
        {
          text: '美食、烹飪',
          value: '美食、烹飪',
          checked: false,
        },
        {
          text: '政治、理財、投資',
          value: '政治、理財、投資',
          checked: false,
        },
        {
          text: '汽車、改裝相關',
          value: '汽車、改裝相關',
          checked: false,
        },
        {
          text: '3C、科技',
          value: '3C、科技',
          checked: false,
        },
        {
          text: '線上遊戲、動漫',
          value: '線上遊戲、動漫',
          checked: false,
        },
        {
          text: '寵物',
          value: '寵物',
          checked: false,
        },
        {
          text: '親子活動',
          value: '親子活動',
          checked: false,
        },
        {
          text: '攝影、戶外活動、旅遊',
          value: '攝影、戶外活動、旅遊',
          checked: false,
        },
        {
          text: '運動 / 觀看運動賽事',
          value: '運動 / 觀看運動賽事',
          checked: false,
        },
        {
          text: '視聽娛樂(追劇、演唱會、展覽等)',
          value: '視聽娛樂(追劇、演唱會、展覽等)',
          checked: false,
        },
        {
          text: '收藏:公仔、模型、手錶、鞋',
          value: '收藏:公仔、模型、手錶、鞋',
          checked: false,
        },
      ],
      storeOption: [],
      saleList: [],
      lat: '',
      lon: '',
    }
  },
  created() {
    this.fetchStore()
  },
  async mounted(){
    this.$swal({
      html: `<div>為提供您最佳服務，將判斷您所屬位置後即可填寫問卷及抽獎!<br><span style="color:red;font-size: 12px">*如不同意，將無法參加活動，要注意喔!</span></div>`,
    }).then(res =>{
      if(res.value){
        this.getGeoLocation()
            .then(res =>{
              console.log(res)
              this.lon = res.lon
              this.lat = res.lat
            })
            .catch(err =>{
                console.log(err)
                liff.closeWindow()
            })
      }
    })
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('initCheck')
    window.localStorage.setItem('recaptcha', token)
    let url = process.env.VUE_APP_APIUrl + 'saveRecaptcha'
    let data = {
      recaptchaToken: token
    }
    await this.axios.post(url, data,{
      headers:{
        Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
      }
    })
        .then(res =>{
          console.log(res)
        })
        .catch(e =>{
          console.log(e.response.data.msg)
        })
  },
  watch:{
    name: {
      handler: function (newValue) {
        if (newValue == '') {
          this.nameEmpty = true
        } else {
          this.nameEmpty = false
          if(newValue.split('').length > 5){
            this.nameType = true
          }else{
            let regex = /^[\u4e00-\u9fa5]{1,5}$/;
            if (!regex.test(newValue)){
              this.nameType = true
            }else{
              this.nameType = false
            }
          }
        }
      },
    },
    Q9Options:{
      handler: function(newValue) {
        let i = 0
        newValue.forEach(item =>{
          if(item.checked){
            i++
          }
        })
        if(i > 3){
          this.moreError = true
        } else{
          this.moreError = false
        }
      },
      deep: true
    },
    Q8Options:{
      handler: function(newValue) {
        let i = 0
        newValue.forEach(item =>{
          if(item.checked){
            i++
          }
        })
        if(i > 2){
          this.more1Error = true
        } else{
          this.more1Error = false
        }
      },
      deep: true
    },
    store:{
      handler: function(newValue) {
        if(newValue !== ''){
          this.fetchSaleList()
        }
      }
    },
    phone:{
      handler: function(newValue){
        let regex = /^09[0-9]{8}$/;
        if (!regex.test(this.phone)){
          this.phoneError = true
        }else if (newValue === '') {
          this.phoneError = true
        }else{
          this.phoneError = false
        }
      },
    },
  },
  methods:{
    submitForm(){
      if(this.agree){
        this.isLoading = true
        let A8 = ''
        let A9 = ''
        this.Q8Options.forEach(item =>{
          if(item.checked){
            if(A8 === ''){
              A8 = item.value
            }else{
              A8 = A8 + '&' + item.value
            }
          }
        })
        this.Q9Options.forEach(item =>{
          if(item.checked){
            if(A9 === ''){
              A9 = item.value
            }else{
              A9 = A9 + '&' + item.value
            }
          }
        })
        this.isLoading = false
        let data = {
          "reason": "賞車",
          "name": this.name,
          "mobile": this.phone,
          "married": this.marriage,
          "gender": this.gender,
          "birthday": this.birthday,
          "cartype": A8,
          "interest": A9,
          "ownerbrand": this.ownerbrand,
          "dealer": this.dealer,
          "sr_cd": this.store,
          "salesman":this.sale,
          "ministry": this.ministry,
          "longitude":this.lon,
          "latitude": this.lat,
          "recaptchaToken": window.localStorage.getItem('recaptcha')
        }

        if(this.phone === ''){
          this.phoneError = true
        }

        this.checkName()

        if(this.phoneError === false &&
            this.nameEmpty === false &&
            this.nameType === false &&
            this.moreError === false &&
            this.more1Error === false){
          this.recaptcha(data)
        }

      } else {
        this.$swal({
          text: '請同意條款',
        })
      }
    },
    checkName(){
      if(this.name === ''){
        this.nameEmpty = true
      }else{
        this.nameEmpty = false
      }
    },
    getGeoLocation(){
      return new Promise((resolve, reject) =>{
        if(navigator.geolocation){
          navigator.geolocation.getCurrentPosition(function(position){
            let lat = position.coords.latitude
            let lon = position.coords.longitude
            let data = {
              lat:  lat,
              lon: lon
            }
            resolve(data)
          },function(){
            reject()
          })
        } else{
          this.$swal({
            text: '您的瀏覽器不支援 顯示地理位置 API ，請使用其它瀏覽器開啟 這個網址',
          })
          reject('browser not support')
        }
      })
    },
    async recaptcha(data) {
      this.isLoading = true
      // await this.$recaptchaLoaded()
      // const token = await this.$recaptcha('submitForm')
      // console.log(token)
      // data.recaptchaToken = token
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'saveInVisitorAnswer'
      let url = process.env.VUE_APP_APIUrl + 'saveInVisitorAnswer'
      await this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        console.log(res)
        this.isLoading = false
        liff.closeWindow()
      })
      .catch(e =>{
        console.log(e)
        this.isLoading = false
        if(e.response.data.msg === 'invalid paramaters'){
          this.$swal({
            text: '請檢查輸入欄位格式正確，不可為空白',
          })
        }else{
          this.$swal({
            text: e.response.data.msg,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "確定"
          }).then((result) => {
            console.log(result)
            liff.closeWindow()
          })
        }
        this.isLoading = false
      })
    },
    async fetchStore(){
      this.storeDataLodging = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'getSalesNodeList'
      let url = process.env.VUE_APP_APIUrl + 'getSalesNodeList'
      let data = {
        "dealer": this.dealer,
        "ministry":this.ministry
      }
      await this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        if(res.data.msg === 'success'){
          console.log(res.data.data)
          this.storeOption = []
          this.storeOption = res.data.data
          this.storeDataLodging = false
        } else {
          //錯誤處理
          this.storeDataLodging = false
        }
      })
      .catch(e =>{
        console.log(e)
        this.$swal({
          text: e.response.data.msg,
        })
        this.storeDataLodging = false
      })
    },
    async fetchSaleList(){
      this.salesDataLodging = true
      // let url = 'https://project-cmc-as-api-web-dev-01.azurewebsites.net/api/' + 'getSalesmanList'
      let url = process.env.VUE_APP_APIUrl + 'getSalesmanList'
      let data = {
        "dealer": this.dealer,
        "sr_cd": this.store
      }
      await this.axios.post(url, data, {
        headers:{
          Authorization: 'Bearer ' + window.localStorage.getItem('AccessToken')
        }
      }).then( res =>{
        if(res.data.msg === 'success'){
          console.log(res.data.data)
          this.saleList = res.data.data
          this.salesDataLodging = false

        } else {
          //錯誤處理
          this.salesDataLodging = false
        }
      })
          .catch(e =>{
            this.$swal({
              text: e.response.data.msg,
            })
            this.salesDataLodging = false
            console.log(e)
          })
    },
    closeModal(){
      this.openModal= false
      this.pageName = '從菱開始 好友召集令 問卷'
    },
    openPpModal(){
      this.openModal= true
      this.pageName = '個資同意'
    }
  }
}
</script>

<style lang="scss" scoped>

input{
  color:black !important;
}

.w-full{
  width: 100% !important;
}
.content-block{
  width: 95%;
  margin: 16px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px -4px 0px #D9363E;
  min-height: 100vh;
}

.bg{
  background: #EFEFEE;
  position: relative;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  .bg-img-top-right{
    position: absolute;
    top: 0;
    right: 0;
  }
  .bg-img-bottom-left{
    position: absolute;
    bottom: 0;
    left: 0;
  }

}

.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .controller{
    display: inline-flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 12px auto;
    .no{
      width: 15%;
      font-size: 20px;
      font-weight: bold;
      color: gray;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sub-title{
      width: 85%;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    .form-controller{
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      z-index: 10;
      .space{
        width: 15%;
        height: 5px;
      }
      .option-block{
        width: 85%;
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 8px;
        .half{
          width: 50%;
          height: 50px;
          input[type = 'checkbox']{
            width: 16px;
            height: 16px;
          }
          label{
            font-size: 15px;
            margin: 4px 6px;
            font-weight: bold;
          }
        }
      }
      input[type = 'checkbox']{
        width: 16px;
        height: 16px;
      }
      input[type = 'radio']{
        width: 16px;
        height: 16px;
      }
      input[type = 'text']{
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        margin-top: 6px;
        text-align: center;
        background: #fff;
      }
      input[type = 'date']{
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        margin-top: 6px;
        text-align: center;
        background: #fff;
      }
      select{
        width: 85%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        text-align: center;
        margin-top: 8px;
        background: #fff;
      }
      input:focus, select:focus{
        outline: none;
      }
      label{
        font-size: 15px;
        margin: 4px 6px;
        font-weight: bold;
      }
    }
  }
}

input{
  color: black !important;
  text-align: center !important;
}

.X{
  position: absolute;
  top: 6px;
  left: 22px;
  font-size: 32px;
  color: white;
  z-index: 10;
}

.errorMsg{
  width: 100%;
  margin-top: 8px;
  color: #C40017;
  font-size: 16px;
  text-align: right;
}
</style>