<template>
  <div style="position: relative">
    <div class="bg binding">
      <titleBar :titleName="pageName"></titleBar>
      <div class="X" v-if="showPrivacy" @click="closePrivacy">X</div>
      <div class="content-block">
        <div class="content" :class="{ [`step-${step}`]: true, showPrivacy }">
          <template v-if="step === 1">
            <template v-if="showPrivacy">
              <section class="privacy">
                <p class="title">隱私權保護聲明</p>
                <ol>
                  <li>
                    『本公司』或『我們』即為中華汽車工業股份有限公司、華菱汽車股份有限公司以及綠捷股份有限公司。
                  </li>
                  <li>
                    『IOV會員服務』即我們提供之功能和服務，包括透過（a）我們的網站mitsubishi-motors.com.tw
                    、china-motor.com.tw、5230.com.tw、emoving.com.tw、greentrans.com.tw、grandlancer.mitsubishi-motors.com.tw、outlander.com.tw、coltplus.com.tw、zinger.com.tw、等
                    （包括各產品網站、子網域、行動版本）；（b）其他現在或未來開發之軟體（如APP）、產品、媒體、服務或裝置。
                  </li>
                  <li>
                    『會員』即您透過IOV會員服務，提供您之個人資料，向我們註冊成為IOV會員服務之使用者。
                  </li>
                  <li>
                    本公司非常感謝您對我們的優良產品有興趣，並且使用IOV會員服務！關於您個人的隱私權，本公司會絕對尊重並提供完整之保護，我們亦將依照現行法律規範致力於維護您的隱私權，請您安心的使用IOV會員服務。但請您注意，對於IOV會員服務所連結至其他網站並不在本隱私權聲明保障之範圍。
                  </li>
                </ol>
                <p class="title">隱私權聲明之認同</p>
                <p>
                  如您使用IOV會員服務，即表示您接受本隱私權聲明。如果您不接受本隱私權聲明時，請勿在IOV會員服務提供您的個人資料。請您瞭解，如果您選擇不在IOV會員服務上提供個人基本資料，您將無法使用部分內容，例如進入IOV會員服務特定頁面或區域、簡訊或推播、參加抽獎或比賽活動、索取型錄或樣品、或收到我們寄給您的電子郵件。
                </p>
                <p class="title">隱私權保護政策的適用範圍</p>
                <p>
                  隱私權保護政策內容，包括如何處理在您使用IOV會員服務時收集到的個人識別資料。隱私權保護政策不適用於IOV會員以外的相關連結網站和APP，也不適用於非IOV會員所委託或參與管理的人員。
                </p>
                <p class="title">個人資料的蒐集、處理及利用方式</p>
                <ol class="disc">
                  <li>
                    當您使用IOV會員服務所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，IOV會員不會將個人資料用於其他用途。
                  </li>
                  <li>
                    IOV會員服務在您使用各種相關服務、問卷調查等互動性功能時，會保留您所提供的個人資料，例如：姓名、地址、電話號碼、信用卡資料、暱稱、電子郵件地址及使用時間等。
                  </li>
                  <li>
                    於瀏覽一般IOV會員服務時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進IOV會員服務的參考依據，此記錄為內部應用，決不對外公佈。
                  </li>
                  <li>
                    為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
                  </li>
                  <li>
                    基於IOV會員所提供之各項會員服務，您同意於註冊時提供正確詳實之個人資料，您所登錄之資料事後有變更時，應隨時於線上更新之，或回覆郵件（0800@china-motor.com），表明您有意修改或刪除個人資料的要求，我們即會按您的要求作出變更。
                  </li>
                </ol>
                <p class="title">資料之保護</p>
                <ol class="disc">
                  <li>
                    IOV會員服務主機均設有防火牆、防毒系統等科技化和組織性防衛措施相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將承擔相關的法律責任。
                  </li>
                  <li>
                    如因業務需要有必要委託其他單位提供服務時，我們亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
                  </li>
                </ol>
                <p class="title">IOV會員服務對外的相關連結</p>
                <p>
                  IOV會員服務提供其他網站的網路連結，您也可經由所提供的連結，點選進入其他網站或開啟其他軟體項目。但該連結網站不適用IOV會員服務的隱私權保護政策，您必須參考該連結中的隱私權保護政策。
                </p>
                <p class="title">與第三人共用個人資料之政策</p>
                <p>
                  IOV會員服務絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合作廠商或個人合約義務者，不在此限。
                  前項但書之情形包括不限於：
                </p>
                <ol class="disc">
                  <li>經由您書面同意。</li>
                  <li>法律明文規定。</li>
                  <li>為免除您生命、身體、自由或財產上之危險。</li>
                  <li>
                    與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
                  </li>
                  <li>
                    當您在IOV會員服務的行為，違反服務條款或可能損害或妨礙網站或APP與其他使用者權益或導致任何人遭受損害時，經管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                  </li>
                  <li>有利於您的權益。</li>
                  <li>
                    IOV會員服務委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
                  </li>
                </ol>

                <p class="title">Cookie之使用</p>
                <p>
                  為了提供您最佳的服務，IOV會員服務會在您的電腦或穿戴手持裝置中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至IOV會員服務某些功能無法正常執行。
                </p>
                <p class="title">隱私權保護政策之修正</p>
                <p>
                  本公司保留得逕自變更或撤銷本隱私權聲明之權利，並建議您經常瀏覽本隱私權聲明以掌握最新資訊。
                </p>
              </section>
            </template>
            <template v-else>
              <div class="info">
                <p class="info-title">帳號認證說明</p>
                <ol>
                  <li>
                    請輸入您入職時登記於公司的手機號碼，才可以順利綁定您的LINE帳號喔。
                  </li>
                  <li>
                    綁定帳號後，透過<span class="warn">樁腳轉介</span>
                    預約客戶資料，將會馬上透過LINE官方帳號通知您。
                  </li>
                  <li class="warn">
                    注意：一個LINE帳號僅能綁定一個手機號碼（不可重複綁定、綁定後無法更動）。請勿填寫他人手機號碼代替他人綁定，以免影響雙方權益。
                  </li>
                </ol>
              </div>
              <div class="input-block">
                <label for="phone">手機號碼</label>
                <input
                  v-model="phone"
                  name="phone"
                  type="text"
                  inputmode="numeric"
                  maxlength="10"
                  placeholder="請輸入手機號碼"
                />
              </div>
              <p class="errorMsg" v-if="phoneError">請確認手機號碼格式</p>
              <div class="input-block">
                <label for="salesmanCode">員工編號</label>
                <input
                  v-model="salesmanCode"
                  name="salesmanCode"
                  type="text"
                  maxlength="10"
                  placeholder="請輸入員工編號"
                />
              </div>
              <p class="errorMsg" v-if="salesmanCodeError">
                請確認員工編號格式
              </p>
              <button @click="openPrivacy" class="btn-block btn-agree">
                隱私權政策
              </button>
              <div class="check-box-block">
                <input type="checkbox" v-model="agree" id="agreeBox" />
                <label for="agreeBox">我同意上述政策及其條款</label>
                <p v-if="agreeError" class="errorMsg">請同意條款</p>
              </div>
              <button @click="sendOtp" class="btn-block">確認</button>
            </template>
          </template>
          <template v-if="step === 2">
            <p>
              我們將寄送認證簡訊至您所輸入的手機號碼
              <br />
              {{ formatPhone(phone) }}
            </p>
            <div class="input-block">
              <input
                type="text"
                v-model="otp"
                inputmode="numeric"
                autocomplete="one-time-code"
                maxlength="4"
                placeholder="請輸入驗證碼"
                style="margin: auto"
              />
            </div>
            <p class="errorMsg" v-if="otpError">請確認驗證碼格式</p>
            <button class="btn-block" @click="validateOtp">驗證</button>
            <p class="hint-title">沒收到簡訊？</p>
            <button
              class="btn-block sub"
              :disabled="otpSecond > 0"
              @click="sendOtp"
            >
              {{
                otpSecond > 0 ? `重新發送驗證碼 (${otpText})` : '重新發送驗證碼'
              }}
            </button>
            <p class="hint">
              若未收到簡訊可能為設定拒收企業簡訊，請與電信公司聯繫。
            </p>
          </template>
          <template v-if="step === 3">
            <div class="form-block">
              <span class="label">電話</span>
              <span class="data">{{ formatPhone(phone) }}</span>
            </div>
            <div class="form-block">
              <span class="label">姓名</span>
              <span class="data">{{ salesmanName }}</span>
            </div>
            <div class="form-block">
              <span class="label">員工編號</span>
              <span class="data">{{ salesmanCode }}</span>
            </div>
            <button @click="closeLiff" class="btn-block">確認</button>
          </template>
        </div>
      </div>
      <img src="../../assets/img/bg1.svg" alt="" class="bg-img-top-right" />
      <img
        src="../../assets/img/bg2.svg"
        alt=""
        class="bg-img-bottom-left"
        @click="openConsole"
      />
    </div>
    <loading :isLoading="isLoading"></loading>
  </div>
</template>

<script>
import loading from '../../components/loading'
import titleBar from '../../components/titleBar'
import liff from '@line/liff'
import VConsole from 'vconsole'

export default {
  name: 'salesBinding',
  components: {
    loading,
    titleBar
  },
  data() {
    return {
      //system
      vConsole: null,
      clickCount: 0,
      isLoading: false,
      showPrivacy: false,
      pageName: '中華三菱業代帳號認證',
      step: 1,
      otpAsked: null,
      otpSecond: null,
      otpTimer: null,
      //data
      phone: '',
      phoneError: null,
      salesmanCode: '',
      salesmanCodeError: null,
      agree: false,
      agreeError: null,
      otp: '',
      otpError: null,
      salesmanName: null
    }
  },
  async mounted() {
    this.vConsole = new VConsole()
    this.vConsole.hideSwitch()
    await this.checkIfBinding()
  },
  beforeDestroy() {
    this.vConsole.destroy()
  },
  watch: {
    phone: function () {
      this.checkPhone()
    },
    salesmanCode: function () {
      this.checkSalesmanCode()
    },
    agree: function () {
      this.checkAgree()
    },
    otp: function () {
      this.checkOtp()
    }
  },
  computed: {
    otpText: function () {
      if (this.otpSecond === 0) return '0m 0s'
      const minutes = Math.floor(this.otpSecond / 60)
      const seconds = this.otpSecond - minutes * 60
      return `${minutes}m ${seconds}s`
    }
  },
  methods: {
    async checkIfBinding() {
      console.log('checkIfBinding:')
      this.isLoading = true
      await this.axios
        .post(
          process.env.VUE_APP_APIUrl + 'checkSalesmanBinding',
          {},
          {
            headers: {
              Authorization:
                'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }
        )
        .then((res) => {
          console.log('checkSalesmanBinding res', res)
          if (res.data?.data?.hasBind) {
            this.closeLiff()
            return
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.$swal({
            text: e.response.data.msg,
            type: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: '關閉'
          }).then((result) => {
            console.log(result)
            this.closeLiff()
          })
        })
    },
    checkPhone() {
      this.phoneError = !/^09\d{8}$/.test(this.phone)
    },
    checkSalesmanCode() {
      this.salesmanCodeError = !this.salesmanCode
    },
    checkAgree() {
      this.agreeError = !this.agree
    },
    checkOtp() {
      this.otpError = !/^\d{4}$/.test(this.otp)
    },
    openPrivacy() {
      this.showPrivacy = true
      this.pageName = '隱私權條款'
    },
    closePrivacy() {
      this.showPrivacy = false
      this.pageName = this.$options.data().pageName
    },
    async sendOtp() {
      this.checkPhone()
      this.checkSalesmanCode()
      this.checkAgree()
      if (this.phoneError || this.salesmanCodeError || this.agreeError) return

      this.isLoading = true
      await this.axios
        .post(
          process.env.VUE_APP_APIUrl + 'sendSalesmanMobileAuth',
          { mobile: this.phone, salesmanCode: this.salesmanCode },
          {
            headers: {
              Authorization:
                'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }
        )
        .then((res) => {
          console.log('sendOtp res', res)
          clearInterval(this.otpTimer)
          this.otpAsked = true
          this.otpSecond = 60 * 60
          this.otpTimer = setInterval(() => {
            this.otpSecond -= 1
            if (this.otpSecond === 0) {
              clearInterval(this.otpTimer)
            }
          }, 1000)
          this.step = 2
        })
        .catch((e) => {
          console.log('sendOtp err', e)
          if (e.response.data.msg.includes('該工號與手機號碼查無資訊')) {
            this.$swal({
              title: '<p style="font-size: 1.5rem;">【查無此手機號碼】</p>',
              html: '請輸入您入職時登記於公司的手機號碼，才可以順利綁定您的LINE帳號!',
              confirmButtonText: '確定'
            })
            return
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formatPhone(value) {
      if (value.length === 10 && value.startsWith('09')) {
        return value
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(\d{4}-\d{3})(\d{1,2})/, '$1-$2')
      }
      return value
    },
    async validateOtp() {
      console.log('validateOtp')
      this.checkOtp()
      if (this.otpError) return

      this.isLoading = true
      await this.axios
        .post(
          process.env.VUE_APP_APIUrl + 'authSalesmanCodeAndBinding',
          {
            mobile: this.phone,
            salesmanCode: this.salesmanCode,
            authCode: this.otp
          },
          {
            headers: {
              Authorization:
                'Bearer ' + window.localStorage.getItem('AccessToken')
            }
          }
        )
        .then((res) => {
          console.log('validateOtp res', res)
          this.salesmanName = res.data?.data?.salesmanName
          this.step = 3
        })
        .catch((e) => {
          console.log('validateOtp err', e)
          if (e.response.data.msg.includes('該工號與手機號碼查無資訊')) {
            this.$swal({
              html: '手機號碼與工號査無資訊，<br/>請重新確認再輸入',
              confirmButtonText: '確定'
            })
            return
          }
          if (e.response.data.msg.includes('驗證失敗')) {
            this.$swal({
              html: '驗證碼驗證失敗，<br/>請重新確認再輸入',
              confirmButtonText: '確定'
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    openConsole() {
      this.clickCount += 1
      if (this.clickCount >= 10) {
        this.vConsole.showSwitch()
      }
    },
    closeLiff() {
      liff.closeWindow()
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
.binding {
  font-family: Noto Sans TC, serif;
}
.bg {
  background: #efefee;
  position: relative;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  flex-direction: column;
  .bg-img-top-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .bg-img-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.content-block {
  width: 90%;
  // min-height: 100vh;
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px -4px 0px #2f5fab;
  // min-height: 100%;
  flex-grow: 1;
}
.content {
  padding: 30px 5%;
  z-index: 10;
  position: relative;
  &.showPrivacy {
    max-height: calc(
      100vh - 60px - 40px
    ); // 扣掉 titleBar 與 content-block margin
    overflow: auto;
  }
}
.info {
  background: #d9d9d9;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 2px;
  .info-title {
    background: #2f5fab;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 6px 0;
    font-size: 18px;
  }
  ol {
    padding-left: 16px;
    margin: 12px 0 0 8px;
    font-weight: bold;
    color: #2f5fab;
  }
  .warn {
    color: #e50110;
  }
}
.step-2 {
  p {
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .hint-title {
    color: #bcbcbc;
    margin-top: 40px;
  }
  .hint {
    font-size: 12px;
    margin-top: 16px;
    color: #585858;
  }
}
.input-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 6px;
  label {
    flex-shrink: 0;
    font-weight: 500;
  }
  input[type='text'] {
    width: 85%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    text-align: center;
    background: #fff;
  }
}
.errorMsg {
  width: 100%;
  margin-top: 8px;
  color: #c40017;
  font-size: 16px;
  text-align: right;
}
.btn-block {
  background: #2f5fab;
  color: white;
  width: 100%;
  height: 55px;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 20px;
  &.sub {
    background: white;
    color: #2f5fab;
    border: 1px solid #2f5fab;
  }
  &:disabled {
    background: #c1c1c1;
    color: white;
    border: none;
  }
  &.btn-agree {
    width: fit-content;
    height: 40px;
    padding: 0 48px;
    margin-top: 40px;
  }
}
.check-box-block {
  width: 100%;
  margin-top: 12px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  input {
    width: 18px;
    height: 18px;
  }
  label {
    margin-left: 8px;
    line-height: 18px;
    font-weight: 500;
  }
  .errorMsg {
    text-align: left;
  }
}
.X {
  position: absolute;
  top: 6px;
  left: 22px;
  font-size: 32px;
  color: white;
  z-index: 10;
}
.form-block {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  .label {
    flex-shrink: 0;
    font-weight: 500;
  }
  .data {
    width: 85%;
    text-align: right;
  }
}
.step-2 {
  .btn-block {
    margin-top: 60px;
  }
}
.privacy {
  .title {
    font-weight: 500;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  ol {
    margin-left: 16px;
    padding-left: 16px;
    &.disc {
      list-style-type: disc;
    }
  }
}
</style>
