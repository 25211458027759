<template>
  <div class="loading" v-if="isLoading">
    <img src="../assets/img/loading1.svg" alt="">
  </div>
</template>

<script>
export default {
  props:[
    'isLoading'
  ]
}
</script>

<style lang="scss" scoped>
.loading{
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  img{
    width: 100%;
    height: 100%;
  }
}
</style>