import Vue from 'vue'
import VueRouter from 'vue-router'
import testRide from '../views/test_ride.vue'
import phoneCheck from '../views/member/phoneCheck'
import memberBinding from '../views/member/memberBinding'
import smsCheck from '../views/member/smsCheck'
import forgetPassword from '../views/member/forgetPassword'
import changePassword from '../views/member/changePassword'
import register from '../views/member/register'
import memberData from '../views/member/memberData'
import carData from '../views/member/carData'
import insertCarData from '../views/member/insertCarData'
import liffPortal from '../views/liff_portal'
import rnoPortal from '../views/rno_portal'
import scheduleMaintenance from '../views/member/scheduleMaintenance'
import survey1 from '../views/member/survey1'
import survey2 from '../views/member/survey2'
import survey3 from '../views/member/survey3'
import salesBinding from '../views/sales/binding'
import pointsExchange from '@/views/points_exchange.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/pointsExchange',
    name: 'pointsExchange',
    component: pointsExchange
  },
  {
    path: '/',
    name: 'portal',
    component: liffPortal
  },
  {
    path: '/rnoPortal',
    name: 'rnoPortal',
    component: rnoPortal
  },
  {
    path: '/testRide',
    name: 'testRide',
    component: testRide
  },
  {
    path: '/member/phoneCheck',
    name: 'phoneCheck',
    component: phoneCheck
  },
  {
    path: '/member/memberBinding',
    name: 'memberBinding',
    component: memberBinding
  },
  {
    path: '/member/smsCheck',
    name: 'smsCheck',
    component: smsCheck
  },
  {
    path: '/member/forgetPassword',
    name: 'forgetPassword',
    component: forgetPassword
  },
  {
    path: '/member/changePassword',
    name: 'changePassword',
    component: changePassword
  },
  {
    path: '/member/register',
    name: 'register',
    component: register
  },
  {
    path: '/member/memberData',
    name: 'memberData',
    component: memberData
  },
  {
    path: '/member/carData',
    name: 'carData',
    component: carData
  },
  {
    path: '/member/addCarData',
    name: 'insertCarData',
    component: insertCarData
  },
  {
    path: '/member/scheduleMaintenance',
    name: 'scheduleMaintenance',
    component: scheduleMaintenance
  },
  {
    path: '/member/survey1',
    name: 'survey1',
    component: survey1
  },
  {
    path: '/member/survey2',
    name: 'survey2',
    component: survey2
  },
  {
    path: '/member/survey3',
    name: 'survey3',
    component: survey3
  },
  {
    path: '/sales/binding',
    name: 'salesBinding',
    component: salesBinding
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
