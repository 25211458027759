<template>
  <div class="car-card-block">
    <div class="content">
      <div class="input-block">
        <div class="label">
          車牌號碼
        </div>
        <div class="input-content">
          <input type="text" class="engineNumberInput" :value="carData.carNumber" disabled>
        </div>
      </div>
      <div class="input-block">
        <div class="label">
          品牌
        </div>
        <div class="input-content">
          <input type="text" class="engineNumberInput" :value="Number(carData.vendorID) === 0? '中華三菱車系' : '非中華三菱車系'" disabled>
        </div>
      </div>
    </div>
    <div class="remove-btn-block">
      <div class="remove-btn" @click="$emit('remove', car)">
        X
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:[
        'carData'
    ],
    data(){
      return{
        car: {}
      }
    },
    mounted() {
      this.car = this.carData
    },
  }
</script>

<style lang="scss" scoped>
  .car-card-block{
    display: inline-flex;
    z-index: 10;
    width: 90%;
    height: 140px;
    .content{
      width: 90%;
      border: 1px solid #c1c1c1;
      border-radius: 8px;
      padding: 12px;

      .input-block{
        margin-top: 12px;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .label{
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          width: 70px;
        }

        .input-content{
          margin-left: 8px;
          .CarNumberInput{
            border: 1px solid #c1c1c1;
            border-radius: 8px;
            width: 80px;
            height: 40px;
            text-align: center;

            @media screen and (max-width: 376px){
              width: 77px;

            }

          }

          .engineNumberInput{
            border: 1px solid #c1c1c1;
            border-radius: 8px;
            width: 180px;
            height: 40px;
            text-align: center;
          }

          input:focus{
            outline: none;
          }
        }
      }
    }

    .remove-btn-block{
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      .remove-btn{
        width: 20px;
        height: 20px;
        background: #000;
        color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
